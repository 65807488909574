import React from "react";
import { useHistory } from "react-router-dom";
import Avatar from "../../ChatSelectionMenu/Avatar/Avatar";
import CloseIcon from "../../../icons/CloseIcon";
import ArrowLeftIcon from "../../../icons/ArrowLeftIcon";

import useChatContext from "../../../hooks/useChatContext/useChatContext";

export default function ChatWindowHeader({
  avatarUrl,
  text,
  setActiveChannel,
}) {
  const history = useHistory();
  const { setIsChatWindowOpen } = useChatContext();

  return (
    <div
      className={`tkb-h-6 tbk-relative tbk-flex tbk-items-center tbk-justify-start tbk-bg-basic-white tbk-py-2 tbk-pl-2 tbk-pr-1 tbk-pb-2 tbk-shadow-trube lg:tbk-rounded-t-2xl`}
    >
      <button
        className="tbk-text-main-linked tbk-mr-2 tbk-flex tbk-items-center tbk-text-blue hover:tbk-text-blue-light"
        onClick={() => {
          setActiveChannel(null);
          history.replace("/account/messages");
        }}
      >
        <div className="tbk-mr-1 tbk-text-blue">
          <ArrowLeftIcon />
        </div>
        <span>Back</span>
      </button>
      <div className="tbk-flex-col_ tbk-flex tbk-items-center tbk-gap-1">
        <Avatar
          width={40}
          height={40}
          //present={present}
          //bubblePrecedent={avatarBubblePrecedent}
          avatarUrl={avatarUrl}
        />
        <div className={`tbk-text-h3-subtitle tbk-text-primary`}>{text}</div>
      </div>
    </div>
  );
}
