import React from "react";
// import Image from 'next/image'

import avatarPlaceholder from "../../../images/generic-trainerphoto.svg"; //.png

export default function Avatar({
  avatarUrl,
  present = -1,
  bubblePrecedent = "",
  width = 32,
  height = 32,
  editIcon = false,
  editActionHandler = () => {},
  border = false,
}) {
  return (
    <div className="relative tbk-shrink-0 tbk-rounded-full tbk-shadow-trube">
      {/*<Image
        src={avatarUrl ? avatarUrl : '/avatars/placeholder.png'}
        alt='User avatar'
        className={`rounded-full ${border && 'border-2 border-white'}`}
        width={width}
        height={height}
      />*/}
      <img
        src={avatarUrl ? avatarUrl : avatarPlaceholder}
        alt="User avatar"
        className={`tbk-h-5 tbk-w-5 tbk-rounded-full ${
          border && "tbk-border-2 tbk-border-basic-white"
        }`}
        width={width}
        height={height}
      />
      {/* Presence Indicator */}
      {present != -1 &&
        bubblePrecedent === "" &&
        (present > 0 ? (
          <div className="tbk-bg-success tbk-absolute tbk-left-[22px] tbk-top-[20px] tbk-h-[12px] tbk-w-[12px] tbk-rounded-full tbk-border-2 tbk-border-basic-white"></div>
        ) : (
          <div className="tbk-bg-neutral300 tbk-absolute tbk-left-[22px] tbk-top-[20px] tbk-h-[12px] tbk-w-[12px] tbk-rounded-full tbk-border-2 tbk-border-basic-white"></div>
        ))}
      {bubblePrecedent !== "" && (
        <div className="tbk-border-navy50 tbk-bg-neutral-100 tbk-absolute tbk-left-[18px] tbk-top-[16px] tbk-h-[20px] tbk-w-[22px] tbk-rounded-full tbk-border tbk-text-xs">
          {bubblePrecedent}
        </div>
      )}
      {editIcon && (
        <div
          className={`tbk-border-sky-950 tbk-bg-sky-950 cursor-pointer tbk-absolute tbk--right-[15px] tbk--bottom-[15px] tbk-m-2 tbk-h-[40px] tbk-w-[40px] tbk-rounded-full tbk-border-2 tbk-text-xs`}
        >
          <div onClick={(e) => editActionHandler()}>
            {/*<Image
              src={'/icons/edit.svg'}
              alt='Edit'
              className='rounded-full white p-1'
              width={40}
              height={40}
              priority
            />*/}
            <img
              src={"/icons/edit.svg"}
              alt="Edit"
              className="tbk-white tbk-rounded-full tbk-p-1"
              width={40}
              height={40}
              // priority
            />
          </div>
        </div>
      )}
    </div>
  );
}
