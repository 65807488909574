import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import ChatWindowHeader from "./ChatWindowHeader/ChatWindowHeader";
import ChatInput from "./ChatInput/ChatInput";
import clsx from "clsx";
import ChatSelectionMenu from "../ChatSelectionMenu/ChatSelectionMenu";
import MessageList from "./MessageList/MessageList";
import { Channel, User } from "@pubnub/chat";
import {
  UnreadMessagesOnChannel,
  LatestMessagesOnChannel,
} from "../../context/ChatProvider";
import useChatContext from "../../hooks/useChatContext/useChatContext";
import avatarPlaceholder from "../../images/generic-trainerphoto.svg";

// In this component, we are toggling the visibility of the ChatWindow with CSS instead of
// conditionally rendering the component in the DOM. This is done so that the ChatWindow is
// not unmounted while a file upload is in progress.

export default function ChatWindow() {
  const history = useHistory();
  const location = useLocation();
  const {
    isChatWindowOpen,
    messages,
    chatClient,
    activeChannel,
    setActiveChannel,
    activeChannelPinnedMessage,
    setActiveChannelPinnedMessage,
    setUnreadMessages,
    currentUser,
    conversation,
    privateGroups,
    privateGroupsUsers,
    directChats,
    directChatsUsers,
    publicChannels,
    publicChannelsUsers,
    publicChannelsMemberships,
    privateGroupsMemberships,
    directChatsMemberships,
    unreadMessages,
    latestMessages,
    updateChannelMembershipsForPublic,
    updateChannelMembershipsForDirects,
    updateChannelMembershipsForGroups,
  } = useChatContext();

  const [userMsg, setUserMsg] = useState({
    message: "Message Text.  Message Text.  ",
    title: "Please Note:",
    href: "http://www.pubnub.com",
    type: 0,
  });
  const [userMsgShown, setUserMsgShown] = useState(false);
  const [userMsgTimeoutId, setUserMsgTimeoutId] = useState(0);
  const [refreshMembersTimeoutId, setRefreshMembersTimeoutId] = useState();

  const [showThread, setShowThread] = useState(false);
  const [chatSelectionMenuMinimized, setChatSelectionMenuMinimized] =
    useState(false);
  const [creatingNewMessage, setCreatingNewMessage] = useState(false);
  const [isChatCreating, setIsChatCreating] = useState(false);

  useEffect(() => {
    // if (!isChatWindowOpen) {
    //   history.push("/");
    // }
    return () => {
      setActiveChannel(null);
    };
  }, [isChatWindowOpen]);

  // Extract userId from the URL
  const userIdFromUrl = location.pathname.split("/").pop();

  useEffect(() => {
    const openDirectChat = async () => {
      if (
        isChatCreating ||
        !chatClient ||
        !directChats ||
        !userIdFromUrl ||
        userIdFromUrl === "messages"
      ) {
        return;
      }

      // Check if a direct chat with the user already exists
      const existingDirectChat = directChats.find((channel) => {
        const usersInChannel = directChatsUsers[directChats.indexOf(channel)];
        return usersInChannel.some((user) => user.id === userIdFromUrl);
      });

      if (existingDirectChat) {
        // If it exists, set it as the active channel
        setActiveChannel(existingDirectChat);
      } else {
        // If it doesn't exist, create a new direct conversation
        try {
          // reference the person you want to talk to
          const userToChatWith = await chatClient.getUser(userIdFromUrl);

          setIsChatCreating(true);
          const { channel: newChannel } =
            await chatClient.createDirectConversation({
              user: userToChatWith,
            });
          setActiveChannel(newChannel);
          // Optionally, update memberships
          await updateChannelMembershipsForDirects(chatClient);
        } catch (error) {
          setIsChatCreating(false);
          console.error("Error creating direct conversation:", error);
        }
        setIsChatCreating(false);
      }
    };

    openDirectChat();
  }, [
    chatClient,
    userIdFromUrl,
    directChats,
    directChatsUsers,
    setActiveChannel,
    updateChannelMembershipsForDirects,
    isChatCreating,
  ]);

  /*
  Will refresh all of the users and channels associated with this user's memberships
  You could do this using the objects from the StreamUpdatesOn() callbacks, but
  this way is expedient for a proof of concept.  The Channel name updates use the StreamUpdatesOn()
  callback directly.
  */
  const refreshMembersFromServer = useCallback(
    async (
      forceUpdateDirectChannels = false,
      forceUpdateGroupChannels = false,
      desiredChannelId = ""
    ) => {
      if (!chatClient) return;
      //return //  TODO REMOVE THIS TO ENABLE OBJECT UPDATES.  IT'S JUST A PAIN WHEN DEBUGGING

      // @ts-ignore
      clearTimeout(refreshMembersTimeoutId);

      if (forceUpdateDirectChannels) {
        //updateChannelMembershipsForPublic(chat)  //  Not needed as we only call this when we create a new group or DM
        updateChannelMembershipsForDirects(chatClient, desiredChannelId);
      } else if (forceUpdateGroupChannels) {
        updateChannelMembershipsForGroups(chatClient, desiredChannelId);
      } else {
        let setTimeoutId = setTimeout(() => {
          updateChannelMembershipsForPublic(chatClient);
          updateChannelMembershipsForDirects(chatClient);
          updateChannelMembershipsForGroups(chatClient);
        }, 3000);
        setRefreshMembersTimeoutId(setTimeoutId);
      }

      return;
    },
    [chatClient, refreshMembersTimeoutId]
  );

  function updateUnreadMessagesCounts() {
    chatClient?.getUnreadMessagesCounts({}).then((result) => {
      let unreadMessagesOnChannel: UnreadMessagesOnChannel[] = [];
      result.forEach((element, index) => {
        let newUnreadMessage: UnreadMessagesOnChannel = {
          channel: element.channel,
          count: element.count,
        };
        unreadMessagesOnChannel.push(newUnreadMessage);
      });
      setUnreadMessages(unreadMessagesOnChannel);
    });
  }

  function showUserMessage(
    title: string,
    message: string,
    href: string,
    type = 0 //ToastType.INFO
  ) {
    clearTimeout(userMsgTimeoutId);
    setUserMsg({ message: message, href: href, title: title, type: type });
    setUserMsgShown(true);
    let timeoutId = window.setTimeout(setUserMsgShown, 7000, false);
    setUserMsgTimeoutId(timeoutId);
  }

  const currentUserProfileUrl = activeChannel?.custom?.profileUrl;

  return (
    <>
      <div />
      <div />
      <aside
        className={`lg:tbk-min-w-80 tbk-min-w-60 tbk-bottom-7 tbk-right-0 tbk-left-0 tbk-top-7 tbk-z-10 tbk-flex tbk-flex-col tbk-border-blue-grey-light md:tbk-top-0 md:tbk-mr-0 md:tbk-ml-0 md:tbk-w-full lg:tbk-relative lg:tbk-mt-3 lg:tbk-w-166 lg:tbk-pb-8
          ${
            activeChannel
              ? "tbk-fixed tbk-bottom-7"
              : "tbk-w-screen_ tbk-w-full_ tbk-mt-2 tbk--mr-2 tbk--ml-2 "
          }`}
      >
        {activeChannel ? (
          <ChatWindowHeader
            avatarUrl={
              activeChannel?.type === "group"
                ? currentUserProfileUrl
                  ? currentUserProfileUrl
                  : avatarPlaceholder
                : activeChannel?.type == "public"
                ? activeChannel?.custom?.profileUrl
                  ? activeChannel?.custom?.profileUrl
                  : avatarPlaceholder
                : activeChannel?.type == "direct" && directChats
                ? directChatsUsers[
                    directChats.findIndex(
                      (dmChannel) => dmChannel.id == activeChannel?.id
                    )
                  ]?.find((user) => user.id !== chatClient.currentUser.id)
                    ?.profileUrl
                  ? directChatsUsers[
                      directChats.findIndex(
                        (dmChannel) => dmChannel.id == activeChannel?.id
                      )
                    ]?.find((user) => user.id !== chatClient.currentUser.id)
                      ?.profileUrl
                  : avatarPlaceholder
                : avatarPlaceholder
            }
            avatarBubblePrecedent={
              activeChannel?.type === "group" && privateGroups
                ? privateGroupsUsers[
                    privateGroups.findIndex(
                      (group) => group.id == activeChannel?.id
                    )
                  ]?.map((user) => user.id !== chatClient.currentUser.id)
                  ? `+${
                      privateGroupsUsers[
                        privateGroups.findIndex(
                          (group) => group.id == activeChannel?.id
                        )
                      ]?.map((user) => user.id !== chatClient.currentUser.id)
                        .length - 1
                    }`
                  : ""
                : ""
            }
            text={
              activeChannel?.type === "direct" && directChats
                ? directChatsUsers[
                    directChats.findIndex(
                      (dmChannel) => dmChannel.id == activeChannel?.id
                    )
                  ]?.find((user) => user.id !== chatClient.currentUser.id)?.name
                : activeChannel?.name
            }
            setActiveChannel={setActiveChannel}
          />
        ) : null}
        {!activeChannel ? (
          <ChatSelectionMenu
            chatSelectionMenuMinimized={chatSelectionMenuMinimized}
            setChatSelectionMenuMinimized={setChatSelectionMenuMinimized}
            setShowThread={setShowThread}
            chat={chatClient}
            setCreatingNewMessage={setCreatingNewMessage}
            unreadMessages={unreadMessages}
            latestMessages={latestMessages}
            publicChannels={publicChannels}
            publicChannelsMemberships={publicChannelsMemberships}
            privateGroups={privateGroups}
            privateGroupsUsers={privateGroupsUsers}
            privateGroupsMemberships={privateGroupsMemberships}
            directChats={directChats}
            directChatsUsers={directChatsUsers}
            directChatsMemberships={directChatsMemberships}
            activeChannel={activeChannel}
            setActiveChannel={setActiveChannel}
            setActiveChannelPinnedMessage={setActiveChannelPinnedMessage}
            updateUnreadMessagesCounts={() => {
              updateUnreadMessagesCounts();
            }}
            //currentUserProfileUrl={profileUrl}
            showUserMessage={showUserMessage}
          />
        ) : null}
        {activeChannel ? (
          <MessageList
            activeChannel={activeChannel}
            currentUser={currentUser}
            messages={messages}
            groupUsers={
              activeChannel?.type == "group" && privateGroups
                ? privateGroupsUsers[
                    privateGroups.findIndex(
                      (group) => group.id == activeChannel?.id
                    )
                  ]
                : activeChannel?.type == "direct" && directChats
                ? directChatsUsers[
                    directChats.findIndex(
                      (dmChannel) => dmChannel.id == activeChannel?.id
                    )
                  ]
                : publicChannels
                ? publicChannelsUsers[
                    publicChannels.findIndex(
                      (channel) => channel.id == activeChannel?.id
                    )
                  ]
                : []
            }
            groupMembership={
              activeChannel?.type == "group" &&
              privateGroups &&
              privateGroupsMemberships
                ? privateGroupsMemberships[
                    privateGroups.findIndex(
                      (group) => group.id == activeChannel?.id
                    )
                  ]
                : activeChannel?.type == "direct" &&
                  directChats &&
                  directChatsMemberships
                ? directChatsMemberships[
                    directChats.findIndex(
                      (dmChannel) => dmChannel.id == activeChannel?.id
                    )
                  ]
                : activeChannel?.type == "public" &&
                  publicChannels &&
                  publicChannelsMemberships
                ? publicChannelsMemberships[
                    publicChannels.findIndex(
                      (channel) => channel.id == activeChannel?.id
                    )
                  ]
                : null
            }
            usersHaveChanged={() => {
              refreshMembersFromServer();
            }}
            activeChannelPinnedMessage={activeChannelPinnedMessage}
            setActiveChannelPinnedMessage={setActiveChannelPinnedMessage}
          />
        ) : null}
        {activeChannel ? (
          <ChatInput
            activeChannel={activeChannel}
            //conversation={conversation!}
            replyInThread={true}
            isChatWindowOpen={isChatWindowOpen}
          />
        ) : null}
      </aside>
      {/*<BottomNavigation />*/}
    </>
  );
}
