import React from "react";

export default function PauseIcon({ size, className }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="5" y="5" width="6" height="14" rx="2" fill="currentColor" />
      <rect x="13" y="5" width="6" height="14" rx="2" fill="currentColor" />
    </svg>
  );
}
