import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Channel,
  Message as pnMessage,
  Membership,
  User,
  TimetokenUtils,
} from "@pubnub/chat";
import MessageInfo from "./MessageInfo/MessageInfo";
import MessageListScrollContainer from "./MessageListScrollContainer/MessageListScrollContainer";
import TextMessage from "./TextMessage/TextMessage";
import MediaMessage from "./MediaMessage/MediaMessage";
import Avatar from "../../ChatSelectionMenu/Avatar/Avatar";

const getFormattedDate = (timetoken) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const date = TimetokenUtils.timetokenToDate(timetoken);
  const datetime = `${
    months[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`;

  return datetime;
}; // message?.dateCreated?.toLocaleTimeString('en-us', { hour: 'numeric', minute: 'numeric' }).toLowerCase();

export default function MessageList({
  activeChannel,
  currentUser,
  groupUsers,
  groupMembership,
  usersHaveChanged,
  activeChannelPinnedMessage,
  setActiveChannelPinnedMessage,
}) {
  const localParticipant = ""; // room!.localParticipant;

  const [messages, setMessages] = useState([]);
  const [currentMembership, setCurrentMembership] = useState();
  const [readReceipts, setReadReceipts] = useState();
  const messageListRef = useRef(null);
  const [loadingMessage, setLoadingMessage] = useState("");

  function uniqueById(items) {
    const set = new Set();
    return items.filter((item) => {
      const isDuplicate = set.has(item.timetoken);
      set.add(item.timetoken);
      return !isDuplicate;
    });
  }

  useEffect(() => {
    //  UseEffect to handle initial configuration of the Message List including reading the historical messages
    setLoadingMessage("Fetching History from Server...");
    if (!activeChannel) {
      return;
    }

    async function initMessageList() {
      setMessages([]);
      if (groupMembership == null) {
        console.log("Error: groupMembership should not be null");
      }
      var localCurrentMembership = groupMembership;
      setCurrentMembership(groupMembership);
      activeChannel
        .getHistory({ count: 20 })
        .then(async (historicalMessagesObj) => {
          //  Run through the historical messages and set the most recently received one (that we were not the sender of) as read
          if (historicalMessagesObj.messages) {
            if (historicalMessagesObj.messages.length == 0) {
              setLoadingMessage("No messages in this chat yet");
            } else {
              setMessages((messages) => {
                return uniqueById([...historicalMessagesObj.messages]); //  Avoid race condition where message was being added twice
              });
              for (
                var i = historicalMessagesObj.messages.length - 1;
                i >= 0;
                i--
              ) {
                await localCurrentMembership?.setLastReadMessageTimetoken(
                  historicalMessagesObj.messages[i].timetoken
                );
                //updateUnreadMessagesCounts()
                break;
              }
            }
          }
        });
    }
    initMessageList();
  }, [activeChannel]);

  useEffect(() => {
    //  UseEffect to stream Read Receipts
    if (!activeChannel) {
      return;
    }
    if (activeChannel.type == "public") {
      return; //  Read receipts are not supported on public channels
    }

    activeChannel.streamReadReceipts((receipts) => {
      setReadReceipts(receipts);
    });
  }, [activeChannel]);

  useEffect(() => {
    activeChannel?.streamUpdates(async (channelUpdate) => {
      if (channelUpdate.custom) {
        const pinnedMessageTimetoken =
          channelUpdate.custom.pinnedMessageTimetoken;
        if (!pinnedMessageTimetoken) {
          //  Message was unpinned
          setActiveChannelPinnedMessage(null);
        } else {
          channelUpdate.getMessage(pinnedMessageTimetoken).then((message) => {
            setActiveChannelPinnedMessage(message);
          });
        }
      } else {
        setActiveChannelPinnedMessage(null);
      }
    });
  }, [activeChannel]);

  useEffect(() => {
    //  UseEffect to receive new messages sent on the channel
    if (!activeChannel) {
      return;
    }

    return activeChannel.connect((message) => {
      currentMembership?.setLastReadMessageTimetoken(message.timetoken);
      setMessages((messages) => {
        return uniqueById([...messages, message]); //  Avoid race condition where message was being added twice when the channel was launched with historical messages
      });
    });
  }, [activeChannel, currentMembership]);

  useEffect(() => {
    //  UseEffect to receive updates to messages such as reactions.  This does NOT include new messages being received on the channel (which is handled by the connect elsewhere)
    if (!messages || messages.length == 0) {
      return;
    }

    return pnMessage.streamUpdatesOn(messages, setMessages);
  }, [messages]);

  useEffect(() => {
    if (groupUsers && groupUsers.length > 0) {
      return User.streamUpdatesOn(groupUsers, (updatedUsers) => {
        usersHaveChanged();
      });
    }
  }, [groupUsers]);

  const determineUserReadableDate = useCallback((timetoken) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = TimetokenUtils.timetokenToDate(timetoken);

    // Get hours and minutes
    let hours = date.getHours();
    const minutes = date.getMinutes();

    // Determine AM/PM suffix
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Format hours and minutes
    const datetime = `${hours}:${(minutes + "").padStart(2, "0")} ${ampm}`;

    return datetime;
  }, []);

  return (
    <>
      {false && activeChannel?.type == "direct" && (
        <div className="tbk-flex tbk-flex-col tbk-items-center tbk-justify-center tbk-gap-3">
          <div className="tbk--space-x-2.0 tbk-flex tbk-flex-row">
            {groupUsers?.map((member, index) => {
              return member?.id !== currentUser?.id ? (
                <Avatar
                  key={index}
                  avatarUrl={member?.profileUrl}
                  width={40}
                  height={40}
                  // present={
                  //   member?.active ? PresenceIcon.ONLINE : PresenceIcon.OFFLINE
                  // }
                />
              ) : null;
            })}
          </div>
          {/*1:1 between{' '}*/}
          {groupUsers?.map((member, index) =>
            member?.id !== currentUser?.id
              ? `${member.name}${groupUsers.length - 1 != index ? " and " : ""}`
              : null
          )}
        </div>
      )}
      <MessageListScrollContainer
        //ref={messageListRef}
        messages={messages}
      >
        {messages && messages.length == 0 && (
          <div className="tbk-text-main tbk-gap-4_ tbk-h-screen_ tbk-flex tbk-w-full tbk-select-none tbk-flex-col tbk-items-center tbk-justify-center tbk-pt-4 tbk-text-primary">
            {/*<Image
              src='/chat-logo.svg'
              alt='Chat Icon'
              className=''
              width={100}
              height={100}
              priority
            />*/}
            {loadingMessage}
          </div>
        )}
        {messages.map((message, idx) => {
          /*const time = getFormattedTime(message)!;*/

          const prevMsg = messages[idx - 1];

          const previousTime = prevMsg && getFormattedDate(prevMsg.timetoken); //getFormattedTime(messages[idx - 1]);
          const currentTime = getFormattedDate(message.timetoken);
          const time = determineUserReadableDate(message.timetoken);

          // Display the MessageInfo component when the author or formatted timestamp differs from the previous message
          const shouldDisplayMessageInfo = previousTime !== currentTime; //true; //false; //time !== previousTime || message.author !== messages[idx - 1]?.author;

          const isLocalParticipant = true; //localParticipant.identity === message.author;

          return (
            <React.Fragment key={message.timetoken}>
              {shouldDisplayMessageInfo && (
                <MessageInfo
                  //author={message.author!}
                  author={
                    activeChannelPinnedMessage?.userId === currentUser?.id
                      ? currentUser?.name
                      : groupUsers?.find(
                          (user) =>
                            user?.id === activeChannelPinnedMessage?.userId
                        )?.name
                  }
                  isLocalParticipant={isLocalParticipant}
                  dateCreated={currentTime}
                  isOnline={
                    activeChannelPinnedMessage?.userId === currentUser?.id
                      ? currentUser?.active
                      : groupUsers?.find(
                          (user) =>
                            user.id === activeChannelPinnedMessage?.userId
                        )?.active
                  }
                />
              )}
              {message.type === "text" && (
                <TextMessage
                  body={message.text}
                  // key={message.timetoken}
                  received={currentUser?.id !== message?.userId}
                  readReceipts={readReceipts}
                  dateCreated={time}
                  isLocalParticipant={currentUser?.id === message?.userId}
                  //isLocalParticipant={false}
                  currentUserId={currentUser?.id}
                  message={message}
                  senderName={groupUsers?.map((member, index) =>
                    member?.id !== currentUser?.id ? `${member.name}` : null
                  )}
                />
              )}
              {/*{message.type === 'media' && <MediaMessage media={message.attachedMedia![0]} />}*/}
            </React.Fragment>
          );
        })}
      </MessageListScrollContainer>
    </>
  );
}
