/* global fbq */
/**
 * Lifestyle landing page component (UK version)
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/09/27
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import Modal from "react-modal";
import Carousel from "react-gallery-carousel";
import { Title, Meta, Link as LinkMeta } from "react-meta-elements";

import { useAppState } from "../../../state";
import { useRegion } from "../../../context/RegionProvider";

// Components
import Header from "../../Homepage/Header/Header";
import Footer from "../../Footer/Footer";
import ScrollToTopOnMount from "../../ScrollToTopOnMount/ScrollToTopOnMount";
import Button from "../../Button/Button";
import AskConciergeModal from "../AskConciergeModal/AskConciergeModal";
import MembershipBox from "../../LifestyleLP/MembershipBox/MembershipBox";
import ManageMembershipModal from "../../LifestyleLP/ManageMembershipModal/ManageMembershipModal";
import CancelMembershipModal from "../../CancelMembershipModal/CancelMembershipModal";
import PauseMembershipModal from "../../LifestyleLP/MembershipBox/PauseMembershipModal";
import OfferCard from "../../LifestyleLP/OfferCard/OfferCard";
import TruBeInPress from "../../TruBeInPress/TruBeInPress";
import SliderPrevArrow from "../../SliderPrevButton/SliderPrevButton";
import SliderNextArrow from "../../SliderNextButton/SliderNextButton";
import UpcomingExperiencesSlider from "../../LifestyleLP/UpcomingExperiencesSlider/UpcomingExperiencesSlider";
import SpecialOfferBox from "../../LifestyleLP/SpecialOfferBox/SpecialOfferBox";
import AppBanner from "../AppBanner/AppBanner";
import LogoBar from "../../LogoBar/LogoBar";
import MovingBar from "../../MovingBar/MovingBar";
import HeadlineWithSeparator from "../../HeadlineWithSeparator/HeadlineWithSeparator";
import TitleWithSeparator from "../../TitleWithSeparator/TitleWithSeparator";

// Hooks
import useTheme from "../../ThemeProvider/useTheme/useTheme";
import useCurrencyFormat from "../../../hooks/useCurrencyFormat/useCurrencyFormat";

// Utils
import { clientWidth, getPartnerPictureUrl } from "../../../utils";
import { modalStyles } from "../../../constants";

// Icons
import ArrowLeftIcon from "../../../icons/ArrowLeftIcon";
import ArrowRightIcon from "../../../icons/ArrowRightIcon";
import CaretDownIcon from "../../../icons/CaretDownIcon";
import CaretUpIcon from "../../../icons/CaretUpIcon";
import CloseIcon from "../../../icons/CloseIcon";
import CheckboxOIcon from "../../../icons/CheckboxOIcon";
import BlockerIcon from "../../../icons/BlockerIcon";
import CheckNiceIcon from "../../../icons/CheckNiceIcon";
import HeartIcon from "../../Homepage/HeartIcon";
import HighQualityIcon from "../../Homepage/HighQualityIcon";
import LocationIcon from "../../Homepage/LocationIcon";
import OneOnOneSessionsIcon from "../../Homepage/OneOnOneSessionsIcon";

// Images
import heroImg from "../Homepage/hero.jpg";
import heroImg2x from "../Homepage/hero@2x.jpg";
import heroMobileImg from "../Homepage/hero-mobile.jpg";
import heroMobileImg2x from "../Homepage/hero-mobile@2x.jpg";
import lifestyle1 from "./gallery-1@2x.jpg";
import lifestyle2 from "./gallery-2@2x.jpg";
import lifestyle22 from "./gallery-2-2@2x.jpg";
import lifestyle3 from "./gallery-3@2x.jpg";
import lifestyle4 from "./gallery-4@2x.jpg";
import lifestyle5 from "./gallery-5@2x.jpg";
import lifestyle6 from "./gallery-6@2x.jpg";
import lifestyle7 from "./gallery-7@2x.jpg";

import lifestyleCollectiveLogo from "../../Homepage/lifestyle-collective.png";
import lifestyleCollectiveLogo2x from "../../Homepage/lifestyle-collective@2x.png";
import lifestyleCollectiveLogo3x from "../../Homepage/lifestyle-collective@3x.png";

import askConcierge from "../../Homepage/AskConciergeModal/ask-concierge.svg";
import defaultPartnerPhoto from "../../../images/generic-trainerphoto.svg";

import exploreCardImg1 from "./explore-card-pilates.png";
import exploreCardImg12x from "./explore-card-pilates@2x.png";

import exploreCardImg2 from "./explore-card-sound-healing-and-meditation.png";
import exploreCardImg22x from "./explore-card-sound-healing-and-meditation@2x.png";

import exploreCardImg3 from "./explore-card-strength-and-mobility.png";
import exploreCardImg32x from "./explore-card-strength-and-mobility@2x.png";

import exploreCardImg4 from "./explore-card-yoga.png";
import exploreCardImg42x from "./explore-card-yoga@2x.png";

import benefitsExclusiveVenues from "./benefit-card-exclusive-venues.jpg";
import benefitsExclusiveVenues2x from "./benefit-card-exclusive-venues@2x.jpg";

import benefitsClassesByTopCoaches from "./benefit-card-classes-by-top-coaches.jpg";
import benefitsClassesByTopCoaches2x from "./benefit-card-classes-by-top-coaches@2x.jpg";

import benefitsBrandCollabsGifts from "./benefit-card-brand-collabs-gifts.jpg";
import benefitsBrandCollabsGifts2x from "./benefit-card-brand-collabs-gifts@2x.jpg";

import benefitsPostWorkoutBrandActivations from "./benefit-card-post-workout-brand-activations.jpg";
import benefitsPostWorkoutBrandActivations2x from "./benefit-card-post-workout-brand-activations@2x.jpg";

import Lottie from "react-lottie";
import animationData from "../../../lotties/loading-spinner";
import TruBe from "../../Homepage/TruBe";

const INFO_MODAL_HIDE_TIMEOUT = 5000;

const communitySliderImages = [
  lifestyle1,
  lifestyle2,
  lifestyle22,
  lifestyle3,
  lifestyle4,
  lifestyle5,
  lifestyle6,
  lifestyle7,
];

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const sliderResponsiveSettings = [
  {
    breakpoint: 640,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "0",
    },
  },
  {
    breakpoint: 960,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "0",
    },
  },
  {
    breakpoint: 1400,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "0",
    },
  },
  {
    breakpoint: 1600,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "0",
    },
  },
  {
    breakpoint: 5000,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "0",
    },
  },
];

const offersSliderResponsiveSettings = [
  {
    breakpoint: 640,
    settings: {
      slidesToShow: 1,
      initialSlide: 1,
    },
  },
  {
    breakpoint: 960,
    settings: {
      slidesToShow: 2,
      initialSlide: 1,
    },
  },
  {
    breakpoint: 1180,
    settings: {
      slidesToShow: 2,
      initialSlide: 1,
    },
  },
  {
    breakpoint: 1400,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 1,
    },
  },
  {
    breakpoint: 1600,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 1,
    },
  },
  {
    breakpoint: 1920,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 1,
    },
  },
  {
    breakpoint: 2560,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 1,
    },
  },
  {
    breakpoint: 5000,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 1,
    },
  },
  { breakpoint: 10000, settings: "unslick" },
];

const AccordionItem = ({ className, title, text }) => {
  const [collapsed, setCollapsed] = useState(true);

  const handleClick = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  return (
    <div className="tbk-mb-2 tbk-border-b tbk-border-solid tbk-border-blue-grey-light">
      <button
        className={`${
          className ? `${className} ` : ""
        }tbk-flex tbk-w-full tbk-items-start tbk-justify-between tbk-pb-1 tbk-text-primary hover:tbk-text-coral`}
        onClick={handleClick}
      >
        <div className="tbk-text-h3-subtitle tbk-grow tbk-text-left">
          {title}
        </div>
        {collapsed ? <CaretDownIcon size={32} /> : <CaretUpIcon />}
      </button>
      <div
        className="tbk-text-main tbk-overflow-hidden tbk-text-left tbk-text-primary tbk-transition-height"
        style={{
          height: collapsed ? "0" : "auto",
          marginBottom: collapsed ? "0" : "24px",
        }}
      >
        {text}
      </div>
    </div>
  );
};

export const EventCard = ({
  item: eventData,
  to,
  imgClassName,
  showRsvpCta,
  onRsvpCtaClick,
}) => {
  return to || showRsvpCta ? (
    <Link
      to={to || `/events/${eventData.id}`}
      // to={`/events/${evt.id}`}
      // href={`https://trubeapp.com/app-redirects/deeplink-redirect-events.html?id=${evt.id}`}
      // target="_blank"
      className="tbk-mb-2 lg:tbk-mb-0"
      onClick={onRsvpCtaClick}
    >
      <div className="tbk-relative">
        <div className="tbk-absolute tbk-right-3 tbk-top-0 tbk-text-right">
          {eventData.capacity === eventData.currentCapacity ? (
            <div className="tbk-text-main tbk-rounded-b-lg tbk-bg-primary tbk-px-1 tbk-text-basic-white">
              Sold Out
            </div>
          ) : null}
          {eventData.joined && (
            <CheckNiceIcon
              width={24}
              height={24}
              className="tbk-mt-3 tbk-inline-block tbk-rounded-full tbk-border tbk-border-basic-white tbk-bg-green tbk-text-basic-white"
            />
          )}
        </div>
        <img
          className={`${
            imgClassName ? `${imgClassName} ` : ""
          }tbk-mb-2 tbk-w-full tbk-overflow-hidden tbk-rounded-2xl tbk-object-cover tbk-object-left-top`}
          src={eventData.coverPhoto}
          //srcSet={`${access2xImg} 2x, ${access3xImg} 3x`}
          alt="Upcoming Fitness and wellbeing classes"
        />
        {showRsvpCta ? (
          <Button
            variant="secondary"
            size="md"
            className="tbk-w-full"
            endIcon={<ArrowRightIcon />}
          >
            RSVP
          </Button>
        ) : null}
      </div>
    </Link>
  ) : (
    <div className="tbk-relative">
      <div className="tbk-absolute tbk-right-3 tbk-top-0 tbk-text-right">
        {eventData.capacity === eventData.currentCapacity ? (
          <div className="tbk-text-main tbk-rounded-b-lg tbk-bg-primary tbk-px-1 tbk-text-basic-white">
            Sold Out
          </div>
        ) : null}
        {eventData.joined && (
          <CheckNiceIcon
            width={24}
            height={24}
            className="tbk-mt-3 tbk-inline-block tbk-rounded-full tbk-border tbk-border-basic-white tbk-bg-green tbk-text-basic-white"
          />
        )}
      </div>
      <img
        className={`${
          imgClassName ? `${imgClassName} ` : ""
        }tbk-mb-2 tbk-w-full tbk-overflow-hidden tbk-rounded-2xl tbk-object-cover tbk-object-left-top`}
        src={eventData.coverPhoto}
        //srcSet={`${access2xImg} 2x, ${access3xImg} 3x`}
        alt="Upcoming Fitness and wellbeing classes"
      />
      {showRsvpCta ? (
        <Button
          variant="secondary"
          size="md"
          className="tbk-w-full"
          endIcon={<ArrowRightIcon />}
        >
          RSVP
        </Button>
      ) : null}
    </div>
  );
};

export const ExploreCard = ({ title, imgs }) => (
  <div className="tbk-relative tbk-min-w-[330px] tbk-shrink-0 tbk-overflow-hidden tbk-overflow-hidden tbk-rounded-2xl md:tbk-basis-auto lg:tbk-min-w-full xl:tbk-basis-full">
    <picture className="js-progressive-image tbk-h-96 tbk-h-full tbk-w-82 tbk-w-full">
      <source
        srcSet={`${imgs[0]} 1x, ${imgs[1]} 2x`}
        media="(minWidth: 760px)"
        type="image/png"
      />
      <source srcSet={`${imgs[0]} 1x, ${imgs[1]} 2x`} type="image/png" />
      <img
        className="tbk-inline-block tbk-h-full tbk-w-full tbk-transform tbk-object-cover tbk-transition-transform tbk-duration-500 hover:tbk-scale-125"
        aria-hidden="true"
        alt="Curated wellness classes that go beyond the ordinary workout"
        src={imgs[0]}
        style={{ verticalAlign: "bottom" }}
      />
    </picture>
    <div className="md:tbk-text-title-bold tbk-text-cta tbk-pointer-events-none tbk-absolute tbk-left-0 tbk-right-0 tbk-top-1/2 tbk-max-w-full tbk--translate-y-1/2 tbk-text-center tbk-uppercase tbk-text-basic-white md:tbk-normal-case">
      <span className="tbk-px-2">{title}</span>
    </div>
  </div>
);

export const BenefitCard = ({ title, imgs }) => (
  <div className="tbk-relative tbk-min-w-[330px] tbk-shrink-0 tbk-overflow-hidden tbk-overflow-hidden tbk-rounded-2xl md:tbk-basis-auto lg:tbk-min-w-0 xl:tbk-basis-full">
    <picture className="js-progressive-image tbk-h-82 tbk-h-full tbk-w-82 tbk-w-full">
      <source
        srcSet={`${imgs[0]} 1x, ${imgs[1]} 2x`}
        media="(minWidth: 760px)"
        type="image/png"
      />
      <source srcSet={`${imgs[0]} 1x, ${imgs[1]} 2x`} type="image/png" />
      <img
        className="tbk-inline-block tbk-h-full tbk-w-full tbk-object-cover"
        aria-hidden="true"
        alt="Curated wellness classes that go beyond the ordinary workout"
        src={imgs[0]}
        style={{ verticalAlign: "bottom" }}
      />
    </picture>
    <div className="tbk-text-title-bold tbk-absolute tbk-bottom-0 tbk-left-0 tbk-max-w-full tbk-p-3 tbk-text-basic-white md:tbk-normal-case">
      {title}
    </div>
  </div>
);

export default function LifestyleLandingPage() {
  const location = useLocation();
  const { theme } = useTheme();
  const { region } = useRegion();

  const {
    user,
    hasTriedToAutoLogin,
    isTrialAvailable,
    startedEvents,
    upcomingEvents,
    completedEvents,
    membershipItems,
    customerMembership,
    offers,
    partnerLoaded,
    partnerSummary,
    loadUpcomingEvents,
    loadCompletedEvents,
    loadCustomerMembership,
    getMembershipItems,
    loadOffers,
    cancelEventMembership,
    pauseEventMembership,
    unpauseEventMembership,
    //checkTrialAvailable,
    clearCustomerMembership,
    clearCompletedEventsLoaded,
    clearUpcomingEventsLoaded,
    error,
    isFetching,
    setPromoCode,
  } = useAppState();

  const [showMoreQuestions, setShowMoreQuestions] = useState(false);
  const [autoPlay, setAutoPlay] = useState(true);
  const [hasMembershipBeenCancelled, setHasMembershipBeenCancelled] =
    useState(false);
  const [isGalleryCarouselModalOpen, setIsGalleryCarouselModalOpen] =
    useState(null);
  const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] =
    useState(false);
  const [isPauseSubscriptionModalOpen, setIsPauseSubscriptionModalOpen] =
    useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [infoModalTitle, setInfoModalTitle] = useState("");
  const [infoModalMsg, setInfoModalMsg] = useState("");
  const [galleryImages, setGalleryImages] = useState([
    ...communitySliderImages,
  ]);

  const [galleryInitialSlide, setGalleryInitialSlide] = useState(0);
  const [height, setHeight] = useState(
    clientWidth() > theme.responsive.breakpoints.xl
      ? 496
      : clientWidth() > theme.responsive.breakpoints.md
      ? 405
      : 205
  );

  const heroRef = React.useRef(null);
  const infoModalResolveRef = useRef(null);
  const [isAskConciergeModalOpen, setIsAskConciergeModalOpen] = useState(false);
  const [isManageMembershipModalOpen, setIsManageMembershipModalOpen] =
    useState(false);

  const questionsSectionRef = useRef();
  const ytSliderRef = useRef();

  const formatCurrency = useCurrencyFormat("GB");

  useEffect(
    () => {
      if (!hasTriedToAutoLogin) {
        return;
      }

      // if (user) {
      //   checkTrialAvailable(user.id);
      // }

      getMembershipItems({ region: user ? user.region : "GB" });

      loadCompletedEvents(
        user && user.id,
        "COMPLETED",
        { sort: "start,desc", page: 0, size: 4 },
        user ? user.region : "GB"
      );

      loadUpcomingEvents(
        user && user.id,
        "UPCOMING,STARTED,UPCOMING_FULL",
        { sort: "start", page: 0, size: 8 },
        user ? user.region : "GB"
      );

      if (user) {
        loadCustomerMembership(user.id).catch((err) => {
          console.log("Error loading customer membership: ", err);
        });
      }

      loadOffers(user ? user.region : region /*process.env.REACT_APP_REGION*/);
    },
    [user, hasTriedToAutoLogin] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const isUserLoggedIn = !!(user && user.id);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const promoCode = query.get("promocode");

    if (promoCode) {
      setPromoCode(promoCode);
      // Referral code is valid
      openInfoModal(
        `Promo code will be applied at Checkout`,
        "Referral Bonus Applied",
        true
      ).then(() => {
        setIsInfoModalOpen(false);
      });
    }
  }, [location]);

  useEffect(() => {
    // Logout
    if (!user && customerMembership) {
      clearCustomerMembership();
    }
  }, [user, customerMembership]);

  // For logout
  useEffect(() => () => clearCustomerMembership(), []);

  const trainerPictureUrl =
    partnerLoaded && getPartnerPictureUrl(partnerSummary);

  const slickInitialised = true;

  const _partnerMedia = communitySliderImages.map((v) => ({
    type: "IMAGE",
    url: v,
  }));

  //let galleryImages = images; //.map((v) => ({ type: "IMAGE", url: v }));

  const handleMoreQuestionsClick = useCallback(() => {
    setShowMoreQuestions(true);
  }, []);

  const handleSlideSwipe = useCallback(
    (oldIndex, newIndex) => {
      if (autoPlay) {
        setAutoPlay(false);
      }
    },
    [autoPlay]
  );

  const openGalleryCarouselModal = (index) => {
    setGalleryImages([...communitySliderImages]); //.map((v) => ({ type: "IMAGE", url: v }));

    setIsGalleryCarouselModalOpen(true);
    setGalleryInitialSlide(index);
  };

  const closeGalleryCarouselModal = () => {
    setIsGalleryCarouselModalOpen(false);
  };

  const openCancelSubscriptionModal = useCallback(() => {
    setIsCancelSubscriptionModalOpen(true);
  }, []);

  const closeCancelSubscriptionModal = useCallback(() => {
    setIsCancelSubscriptionModalOpen(false);
  }, []);

  const openPauseSubscriptionModal = useCallback(() => {
    setIsPauseSubscriptionModalOpen(true);
  }, []);

  const closePauseSubscriptionModal = useCallback(() => {
    setIsPauseSubscriptionModalOpen(false);
  }, []);

  const openInfoModal = useCallback(
    (msg, title = "Sorry", autoHide = false) => {
      return new Promise((resolve, reject) => {
        infoModalResolveRef.current = resolve;

        setIsInfoModalOpen(true);
        setInfoModalTitle(title);
        setInfoModalMsg(msg);

        if (autoHide) {
          setTimeout(() => resolve(), INFO_MODAL_HIDE_TIMEOUT);
        }
      });
    },
    []
  );

  const closeInfoModal = useCallback(() => {
    infoModalResolveRef.current();
    infoModalResolveRef.current = null;

    setIsInfoModalOpen(false);
  }, []);

  const futureEvents =
    startedEvents && startedEvents.length > 0
      ? [].concat(startedEvents, upcomingEvents)
      : upcomingEvents;

  const pastEventsVideos = [
    "https://www.youtube.com/embed/dEDyOkviibA?feature=oembed&enablejsapi=1",
    "https://www.youtube.com/embed/Ikv2tbbEgXg?feature=oembed&enablejsapi=1",
    "https://www.youtube.com/embed/R5OPaVzrPao?feature=oembed&enablejsapi=1",
  ];

  useEffect(() => {
    if (ytSliderRef.current) {
      setHeight((ytSliderRef.current.offsetWidth * 9) / 16);
    }
  }, [ytSliderRef.current]);

  const handleMembershipCancel = useCallback(
    (reasons) => {
      const userId = user.id;

      cancelEventMembership(userId, reasons)
        .promise.then(() => {
          setHasMembershipBeenCancelled(true);
        })
        .catch((err) => {
          if (err) {
            openInfoModal(err.message);
          }
        })
        .finally(() => {
          closeCancelSubscriptionModal();
        });
    },
    [user]
  );

  const handleMembershipPause = useCallback(() => {
    const userId = user.id;

    if (customerMembership.isPaused) {
      unpauseEventMembership(userId)
        .catch((err) => {
          if (err) {
            openInfoModal(err.message);
          }
        })
        .finally(() => {
          closePauseSubscriptionModal();
        });
    } else {
      pauseEventMembership(userId)
        .catch((err) => {
          if (err) {
            openInfoModal(err.message);
          }
        })
        .finally(() => {
          closePauseSubscriptionModal();
        });
    }
  }, [user, customerMembership]);

  const membershipSubscriptionItem =
    getMembershipSubscriptionItem(membershipItems);
  const membershipPrice = membershipSubscriptionItem
    ? `${membershipSubscriptionItem.price.symbol}${membershipSubscriptionItem.price.amount}`
    : "";

  // const handleCtaClick = useCallback(() => {
  //   window._klOnsite = window._klOnsite || [];
  //   window._klOnsite.push(["openForm", "RkQ7cR"]);
  // }, []);

  const handlePageLeave = useCallback(() => {
    setPromoCode("");
    clearUpcomingEventsLoaded();
  }, []);

  const showSpecialOffer = !customerMembership;

  return (
    <>
      <ScrollToTopOnMount />
      <Title>TruBe: Unique Locations, Unforgettable Fitness Events</Title>
      <Meta
        name="description"
        content="TruBe: Unique Locations, Unforgettable Fitness classes in Pilates, Yoga, HIIT, Cardio, and more. Providing the best coaches and fitness and wellbeing experience."
      />
      <LinkMeta rel="canonical" href="https://trubeapp.com/lifestyle" />
      <Header
        showRegion
        // behaviour="none"
        isFixed
        isLoggedIn={isUserLoggedIn}
        returnTo="/lifestyle"
        heroRef={heroRef}
      />
      <div
        ref={heroRef}
        className="tbk-relative tbk-flex tbk-h-136 tbk-flex-col tbk-overflow-hidden tbk-bg-[#3B5057] tbk-pt-0 lg:tbk-h-[500px]"
      >
        <div className="tbk-h-85_ tbk-absolute tbk-right-0 tbk-bottom-0 tbk-top-0 tbk-left-0 tbk-h-full tbk-w-full tbk-overflow-hidden md:tbk-h-full">
          {/*<video*/}
          {/*  autoPlay*/}
          {/*  loop*/}
          {/*  className="tbk-absolute tbk-m-auto tbk-h-full tbk-w-full tbk-bg-cover tbk-bg-center tbk-object-cover"*/}
          {/*  style={{*/}
          {/*    backgroundImage:*/}
          {/*      "url(https://assets-global.website-files.com/60785b43631c8625d8c10c7e/661d47f9e730db6130053f3f_Lifestyle-poster-00001.jpg)",*/}
          {/*  }}*/}
          {/*  muted*/}
          {/*  playsInline*/}
          {/*>*/}
          {/*  <source src="https://assets-global.website-files.com/60785b43631c8625d8c10c7e/661d47f9e730db6130053f3f_Lifestyle-transcode.mp4" />*/}
          {/*  <source src="https://assets-global.website-files.com/60785b43631c8625d8c10c7e/661d47f9e730db6130053f3f_Lifestyle-transcode.webm" />*/}
          {/*  )}*/}
          {/*</video>*/}
          {/*<div className="tbk-absolute tbk-h-full tbk-w-full tbk-bg-gradient-to-r_ tbk-from-[#3B5057]/60 tbk-to-[#3B5057]/60" />*/}
          <picture className="tbk-h-full tbk-grow">
            <source
              media="(min-width: 768px)"
              srcSet={`${heroImg} 1x, ${heroImg2x} 2x`}
            />
            <source srcSet={`${heroMobileImg} 1x, ${heroMobileImg2x} 2x`} />

            <img
              className="tbk-h-full tbk-w-full tbk-object-cover md:tbk-h-full"
              src={heroImg}
              alt="London’s Best Coaches and Locations for Fitness and Wellbeing Classes"
            />
          </picture>
          {/*<div className="tbk-from-55% tbk-to-100% tbk-absolute tbk-right-0 tbk-bottom-0 tbk-top-0 tbk-left-0 tbk-bg-gradient-to-b tbk-from-[#3B5057]/0 tbk-to-[#3B5057] md:tbk-hidden"></div>*/}
          {/*/*<div className="tbk-absolute tbk-h-full tbk-w-full tbk-bg-gradient-to-r_ tbk-from-[#3B5057]/60 tbk-to-[#3B5057]/60" />*/}
        </div>
        {/*<div className="_tbk-bg-[#C3A48D]/20 tbk-absolute tbk-right-0 tbk-bottom-0 tbk-top-0 tbk-left-0 tbk-bg-gradient-to-b tbk-from-[#3B5057]/60 tbk-to-[#3B5057]/60" />*/}

        <div className="tbk-container tbk-z-1 tbk-mx-auto tbk-px-2 lg:tbk-px-8">
          <div className="tbk-mt-2 tbk-mb-7 tbk-flex tbk-h-full tbk-grow tbk-flex-col tbk-items-center tbk-justify-center lg:tbk-mt-3">
            <div className="tbk-mb-0.5 tbk-flex tbk-flex-col tbk-items-center tbk-justify-center md:tbk-justify-start">
              <TruBe className="" />
              <img
                className="md:tbk-h-24_ tbk-h-9"
                src={lifestyleCollectiveLogo}
                srcSet={`${lifestyleCollectiveLogo} 1x, ${lifestyleCollectiveLogo2x} 2x, ${lifestyleCollectiveLogo3x} 3x`}
                alt="Lifestyle Collective"
              />
            </div>
            <p className="tbk-text-main tbk-mb-2 tbk-text-basic-white lg:tbk-mb-2">
              Elevated Fitness Classes
            </p>

            {showSpecialOffer ? (
              <div className="tbk-mb-4 tbk-flex tbk-grow lg:tbk-mb-4">
                <div className="tbk-mx-3 tbk-w-full sm:tbk-mx-auto">
                  <SpecialOfferBox
                    isUserLoggedIn={isUserLoggedIn}
                    stateFrom={location.pathname}
                    symbol="£"
                    // onCtaClick={() => setPromoCode("TBHOL30")}
                  />
                </div>
              </div>
            ) : null}

            {false && !customerMembership && !showSpecialOffer ? (
              <Link
                to={{
                  pathname: isUserLoggedIn ? `/checkout` : `/become-a-member`,
                  search: isUserLoggedIn
                    ? "?membershipId=1"
                    : "?return_to=/checkout?membershipId=1",
                  state: { from: location.pathname },
                }}
              >
                <Button
                  variant={"secondary"}
                  className="tbk-mt-3 tbk-mb-3 tbk-inline-flex tbk-w-80 tbk-pl-5 tbk-pr-5 lg:tbk-mt-5 lg:tbk-mb-0"
                  endIcon={<ArrowRightIcon className="tbk-ml-0.5" />}
                >
                  Join Now
                </Button>
              </Link>
            ) : null}

            {/*{!customerMembership ? (
              <Button
                variant={"secondary"}
                className="tbk-mt-3 tbk-mb-3 tbk-inline-flex tbk-w-80 tbk-pl-5 tbk-pr-5 lg:tbk-mt-5 lg:tbk-mb-0"
                endIcon={<ArrowRightIcon className="tbk-ml-0.5" />}
                onClick={handleCtaClick}
              >
                Reserve Your Spot
              </Button>
            ) : null}*/}
          </div>
        </div>
      </div>

      {customerMembership ? (
        <div className="tbk-flex">
          <div className="tbk-mx-3 tbk--mt-32 tbk-w-132 tbk-min-w-0 sm:tbk-mx-auto">
            <MembershipBox
              membershipInfo={customerMembership}
              symbol="£"
              region={
                user ? user.region : region /*process.env.REACT_APP_REGION*/
              }
              onCtaClick={() => setIsManageMembershipModalOpen(true)}
            />
          </div>
        </div>
      ) : null}

      {!customerMembership ? (
        <div className="tbk-container tbk-mx-auto tbk-overflow-hidden tbk-px-2 tbk-pt-4 tbk-pb-2 lg:tbk-px-8 lg:tbk-pt-9 lg:tbk-pb-6">
          <TitleWithSeparator
            className="tbk-mb-4"
            titleClass="tbk-text-center tbk-bg-basic-white tbk-text-blue-grey tbk-text-title-bold"
            separatorClass="tbk-border-blue-grey-light"
          >
            Pricing
          </TitleWithSeparator>
          <div className="tb-slider tbk-flex-nowrap tbk-gap-1 lg:tbk-grid lg:tbk-grid-cols-2 lg:tbk-gap-4 lg:tbk-gap-2">
            <div
              className="tbk-relative tbk-flex tbk-w-70 tbk-shrink-0 tbk-grow md:tbk-w-auto"
              //onClick={onClick}
            >
              <div className="tbk-absolute tbk-top-0 tbk-left-0 tbk-h-3 tbk-w-full tbk-rounded-t-2xl tbk-bg-pricing-card-top-border-gradient" />
              <div
                className="tbk-relative tbk-mb-2 tbk-mt-1 tbk-flex tbk-max-w-full tbk-flex-col tbk-overflow-hidden tbk-rounded-2xl tbk-bg-basic-white tbk-p-4 tbk-shadow-trube"
                //onClick={onClick}
              >
                <div className="tbk-text-h2-black tbk-mb-1 tbk-text-primary">
                  Membership
                </div>
                <div className="tbk-text-h3-subtitle lg:tbk-text-title-bold tbk-mb-1 tbk-text-primary">
                  £54 per month
                </div>
                <div className="tbk-text-secondary lg:tbk-text-h3-subtitle tbk-mb-1 tbk-text-primary">
                  <span className="tbk-text-green">4 classes</span> in the first
                  month
                </div>
                <div className="tbk-text-secondary lg:tbk-text-h3-subtitle tbk-mb-1 tbk-text-blue-grey">
                  3 classes per month thereafter
                </div>

                <hr />
                <ul className="tbk-mb-0 tbk-min-w-0 tbk-grow tbk-list-disc tbk-text-left sm:tbk-pl-3 md:tbk-pl-5">
                  <li className="tbk-text-small lg:tbk-text-main tbk-mb-1 tbk-text-primary">
                    Priority RSVP for all classes
                  </li>
                  <li className="tbk-text-small lg:tbk-text-main tbk-mb-1 tbk-text-primary">
                    Welcome kit with a tote bag, water bottle, merch, and more
                  </li>
                  <li className="tbk-text-small lg:tbk-text-main tbk-mb-1 tbk-text-primary">
                    Discounts and deals through our member marketplace
                  </li>
                  <li className="tbk-text-small lg:tbk-text-main tbk-mb-1 tbk-text-primary">
                    15% off private 1:1 training sessions.
                  </li>
                </ul>
                <hr />
                <Link
                  to={{
                    pathname: isUserLoggedIn ? `/checkout` : `/become-a-member`,
                    search: isUserLoggedIn
                      ? "?membershipId=1"
                      : "?return_to=/checkout?membershipId=1",
                    state: { from: location.pathname },
                  }}
                  // href="https://trubeapp.com/app-redirects/deeplink-redirect-events.html"
                  // target="_blank"
                >
                  <Button
                    size="md"
                    className="tbk-w-full"
                    endIcon={<ArrowRightIcon />}
                  >
                    Join Today
                  </Button>
                </Link>
              </div>
            </div>

            <div
              className="tbk-relative tbk-flex tbk-w-70 tbk-shrink-0 tbk-grow md:tbk-w-auto"
              //onClick={onClick}
            >
              <div className="tbk-absolute tbk-top-0 tbk-left-0 tbk-h-3 tbk-w-full tbk-rounded-t-2xl tbk-bg-pricing-card-top-border-gradient" />
              <div
                className="tbk-relative tbk-mb-2 tbk-mt-1 tbk-flex tbk-max-w-full tbk-flex-col tbk-overflow-hidden tbk-rounded-2xl tbk-bg-basic-white tbk-p-4 tbk-shadow-trube"
                //onClick={onClick}
              >
                <div className="tbk-text-h2-black tbk-mb-1 tbk-whitespace-nowrap tbk-text-primary">
                  Pay Per Class
                </div>
                <div className="tbk-text-h3-subtitle lg:tbk-text-title-bold tbk-mb-1 tbk-text-primary">
                  £25 per class
                </div>
                <div className="tbk-text-secondary lg:tbk-text-h3-subtitle tbk-mb-1 tbk-text-blue-grey">
                  Choose any class you like and buy a single ticket as you go.
                </div>

                <hr />
                <ul className="tbk-mb-0 tbk-min-w-0 tbk-grow tbk-list-disc tbk-text-left sm:tbk-pl-3 md:tbk-pl-5">
                  <li className="tbk-text-small lg:tbk-text-main tbk-mb-1 tbk-text-primary">
                    Flexibility and ease – Try a TruBe class on your terms.
                  </li>
                  <li className="tbk-text-small lg:tbk-text-main tbk-mb-1 tbk-text-primary">
                    No commitment – Join us when it suits you, no strings
                    attached.
                  </li>
                </ul>
                <hr />
                <Link to={`/events/upcoming`}>
                  <Button
                    size="md"
                    className="tbk-w-full"
                    endIcon={<ArrowRightIcon />}
                    onClick={handlePageLeave}
                  >
                    View Classes
                  </Button>
                </Link>
              </div>
            </div>
            {/*<PricingCard />*/}
          </div>
        </div>
      ) : null}

      {!customerMembership ? (
        <div
          // ref={firstElementRef}
          className="tbk-flex tbk-flex-col tbk-items-center tbk-justify-center tbk-pt-4 tbk-pb-4 lg:tbk-pt-22 lg:tbk-pb-22"
        >
          <div className="tbk-container tbk-px-2 lg:tbk-px-8">
            <div className="tbk-flex tbk-justify-center">
              <div className="tbk-w-156 lg:tbk-mb-0">
                <h2 className="tbk-text-title-bold lg:tbk-text-h2-black tbk-mb-2 !tbk-leading-tight tbk-text-primary lg:tbk-mb-4 lg:tbk-text-center lg:!tbk-leading-tight">
                  Connect, Inspire, Flourish: Join TruBe's Wellness Community
                </h2>
                <p className="tbk-text-main tbk-mb-7_ tbk-text-primary lg:tbk-text-center">
                  Join the TruBe lifestyle and access curated wellness classes
                  beyond the typical workout. Enjoy weekly sessions led by top
                  coaches in HIIT, Boxing, Pilates, Yoga, Meditation, and more,
                  held at exclusive venues. Each class features pre- and
                  post-activations with select partners, offering a supportive
                  space to connect and prioritize your well-being.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {!customerMembership ? (
        <>
          <div className="tbk-container tbk-mx-auto tbk-px-2 tbk-pt-4 lg:tbk-px-8 lg:tbk-pt-7">
            <TitleWithSeparator
              className="tbk-mb-3"
              titleClass="tbk-text-center tbk-bg-basic-white tbk-text-blue-grey tbk-text-h3-subtitle lg:tbk-text-title-bold"
              separatorClass="tbk-border-blue-grey-light"
            >
              Explore Workouts
              <br className="md:tbk-hidden" /> & Meditations
            </TitleWithSeparator>
          </div>

          <div className="big-container tbk-mx-auto tbk-mb-4 tbk-flex tbk-justify-center">
            <div className="tbk-w-full tbk-max-w-full tbk-overflow-hidden tbk-px-2 lg:tbk-px-2">
              <div className="tb-slider tbk-flex-nowrap tbk-gap-2 lg:tbk-grid lg:tbk-grid-cols-4 lg:tbk-gap-2">
                <ExploreCard
                  imgs={[exploreCardImg1, exploreCardImg12x]}
                  title={"Pilates"}
                />
                <ExploreCard
                  imgs={[exploreCardImg2, exploreCardImg22x]}
                  title={"Sound Healing & Meditation"}
                />
                <ExploreCard
                  imgs={[exploreCardImg3, exploreCardImg32x]}
                  title={"Strength & Mobility"}
                />
                <ExploreCard
                  imgs={[exploreCardImg4, exploreCardImg42x]}
                  title={"Yoga"}
                />
              </div>
            </div>
          </div>

          <div className="tbk-relative tbk-pb-4 tbk-text-center lg:tbk-pb-7">
            <Link to={`/events/upcoming`} className="tbk-inline-block">
              <Button
                variant="secondary"
                className="tbk-w-64"
                endIcon={<ArrowRightIcon />}
                onClick={handlePageLeave}
              >
                Explore All Classes
              </Button>
            </Link>
          </div>
        </>
      ) : null}

      {false && !customerMembership ? (
        <div className="tbk-flex tbk-flex-col tbk-items-center tbk-justify-center tbk-pt-3 lg:tbk-pb-7 lg:tbk-pt-9">
          <div className="tbk-container tbk-px-3 lg:tbk-px-8">
            <div className="tbk-relative tbk-mt-5 tbk-mb-6 tbk-text-center lg:tbk-mt-0">
              <h3 className="tbk-text-h3-subtitle lg:tbk-text-title-bold tbk-inline-block tbk-bg-basic-white tbk-px-2 tbk-text-center tbk-text-blue-grey">
                How it works
              </h3>
              <hr className="tbk-mb-0 tbk--mt-1.5 tbk-border-blue-grey-light lg:tbk--mt-2" />
            </div>
            <div className="tbk-mb-7 md:tbk-grid md:tbk-grid-cols-4 md:tbk-gap-2 lg:tbk-mb-0 lg:tbk-grid-cols-4">
              <div className="tbk-relative tbk-mb-1 tbk-rounded-2xl tbk-bg-basic-white tbk-p-3 tbk-shadow-trube">
                <div className="tbk-text-h3-subtitle tbk-bg-red tbk-absolute tbk-flex tbk-h-4 tbk-w-4 tbk-items-center tbk-justify-center tbk-rounded-full tbk-bg-blue-grey-light tbk-text-center !tbk-leading-relaxed tbk-text-primary">
                  1
                </div>
                <div className=" tbk-text-main tbk tbk-ml-1 tbk-pl-5 tbk-text-primary">
                  Join the Lifestyle Collective & Access 3 Classes Per Month
                </div>
              </div>

              <div className="tbk-relative tbk-mb-1 tbk-rounded-2xl tbk-bg-basic-white tbk-p-3 tbk-shadow-trube">
                <div className="tbk-text-h3-subtitle tbk-bg-red tbk-absolute tbk-flex tbk-h-4 tbk-w-4 tbk-items-center tbk-justify-center tbk-rounded-full tbk-bg-blue-grey-light tbk-text-center !tbk-leading-relaxed tbk-text-primary">
                  2
                </div>
                <div className=" tbk-text-main tbk tbk-ml-1 tbk-pl-5 tbk-text-primary">
                  Choose Your Preferred Classes and RSVP
                </div>
              </div>

              <div className="tbk-relative tbk-mb-1 tbk-rounded-2xl tbk-bg-basic-white tbk-p-3 tbk-shadow-trube">
                <div className="tbk-text-h3-subtitle tbk-bg-red tbk-absolute tbk-flex tbk-h-4 tbk-w-4 tbk-items-center tbk-justify-center tbk-rounded-full tbk-bg-blue-grey-light tbk-text-center !tbk-leading-relaxed tbk-text-primary">
                  3
                </div>
                <div className=" tbk-text-main tbk tbk-ml-1 tbk-pl-5 tbk-text-primary">
                  Receive Your Confirmation & Enjoy the Class!
                </div>
              </div>

              <div className="tbk-relative tbk-mb-1 tbk-rounded-2xl tbk-bg-basic-white tbk-p-3 tbk-shadow-trube">
                <div className="tbk-text-h3-subtitle tbk-bg-red tbk-absolute tbk-flex tbk-h-4 tbk-w-4 tbk-items-center tbk-justify-center tbk-rounded-full tbk-bg-blue-grey-light tbk-text-center !tbk-leading-relaxed tbk-text-primary">
                  4
                </div>
                <div className=" tbk-text-main tbk tbk-ml-1 tbk-pl-5 tbk-text-primary">
                  Receive Promotions & Discounts from Our Favorite Brands
                </div>
              </div>
            </div>
            {/*<Link*/}
            {/*  className="tbk-block tbk-text-center"*/}
            {/*  to={isUserLoggedIn || true ? "/partners" : "/become-a-member"}*/}
            {/*>*/}
            {/*  <Button*/}
            {/*    variant="secondary"*/}
            {/*    className="tbk-inline-flex tbk-w-66 tbk-pl-5 tbk-pr-5"*/}
            {/*    endIcon={<ArrowRightIcon className="tbk-ml-0.5" />}*/}
            {/*  >*/}
            {/*    Start now*/}
            {/*  </Button>*/}
            {/*</Link>*/}
          </div>
        </div>
      ) : null}

      {false && !customerMembership ? (
        <div className="user-select-none tb-moving-bar tbk-flex tbk-w-full tbk-overflow-hidden">
          <MovingBar
            images={communitySliderImages.map((v) => ({
              url: v,
            }))}
            imgClassName="tbk-h-76 tbk-w-76 tbk-rounded-2xl lg:tbk-h-100 lg:tbk-w-100"
            onClick={openGalleryCarouselModal}
          />
          <MovingBar
            images={communitySliderImages.map((v) => ({
              url: v,
            }))}
            imgClassName="tbk-h-76 tbk-w-76 tbk-rounded-2xl lg:tbk-h-100 lg:tbk-w-100"
            onClick={openGalleryCarouselModal}
          />
        </div>
      ) : null}

      {false && !customerMembership ? (
        <div className="tbk-flex tbk-flex-col tbk-items-center tbk-justify-center tbk-pt-4 tbk-pb-4 lg:tbk-pb-7 lg:tbk-pt-7">
          {/*<div className="tbk-container tbk-px-3 lg:tbk-px-8">*/}
          {/*  */}
          {/*</div>*/}
          <div className="tbk-mb-3_ lg:tbk-mb-5_ tbk-relative tbk-text-center">
            <Link
              to={{
                pathname: isUserLoggedIn ? `/checkout` : `/become-a-member`,
                search: isUserLoggedIn
                  ? "?membershipId=1"
                  : "?return_to=/checkout?membershipId=1",
                state: { from: location.pathname },
              }}
            >
              <Button
                //size={"md"}
                className="tbk-w-68"
                endIcon={<ArrowRightIcon />}
                onClick={clearUpcomingEventsLoaded}
              >
                Join the Community
              </Button>
            </Link>
          </div>
        </div>
      ) : null}

      <div className="tbk-flex tbk-flex-col tbk-items-center tbk-justify-center tbk-pt-4 tbk-pb-4 lg:tbk-pb-7 lg:tbk-pt-9">
        <div className="tbk-container tbk-px-3 lg:tbk-px-8">
          <HeadlineWithSeparator
            component="h3"
            titleClass="tbk-bg-basic-white tbk-text-blue-grey tbk-text-h3-subtitle lg:tbk-text-title-bold"
            className="tbk-mb-3 lg:tbk-mb-5"
            separatorClass={"tbk--mt-1.5 lg:tbk--mt-2"}
          >
            Upcoming Classes
          </HeadlineWithSeparator>

          {false && !customerMembership ? (
            <div className="tbk-flex tbk-justify-center tbk-text-center">
              <p
                className={
                  "tbk-text-main lg:tbk-text-h3-subtitle tbk-mb-4 tbk-text-blue-grey lg:tbk-mb-7"
                }
              >
                Experience Three Exclusive Wellness Classes each Month.
                <br className="tbk-hidden md:tbk-block" /> Featuring Diverse
                Modalities, Expert Coaches, bespoke Venues
                <br className="tbk-hidden md:tbk-block" /> and unique brand
                partnerships.
              </p>
            </div>
          ) : null}
          <div className="tbk-mb-6 ">
            <UpcomingExperiencesSlider
              showArrows={clientWidth() > theme.responsive.breakpoints.lg}
              className="tbk-pb-3"
              itemComponent={EventCard}
              itemProps={{
                to: !isUserLoggedIn &&
                  false && {
                    pathname: `/become-a-member`,
                    search: "?return_to=/checkout?membershipId=1",
                    state: { from: location.pathname },
                  },
                imgClassName: "tbk-h-48 lg:tbk-h-76",
                showRsvpCta: true,
                isUserLoggedIn: isUserLoggedIn,
                onRsvpCtaClick: handlePageLeave,
              }}
              futureEvents={futureEvents}
            />
          </div>
        </div>
        <div className="tbk-mb-3_ lg:tbk-mb-5_ tbk-relative tbk-text-center">
          <Link to={`/events/upcoming`} className="tbk-inline-block">
            <Button
              size={"md"}
              className="tbk-w-64"
              endIcon={<ArrowRightIcon />}
              onClick={handlePageLeave}
            >
              View All Upcoming
            </Button>
          </Link>
        </div>
      </div>

      {!customerMembership ? (
        <div className="tbk-pt-7">
          <div className="tbk-container tbk-mx-auto tbk-px-2 lg:tbk-px-8">
            <HeadlineWithSeparator
              component="h3"
              titleClass="tbk-bg-basic-white tbk-text-blue-grey tbk-text-h3-subtitle lg:tbk-text-title-bold"
              className="tbk-mb-3 lg:tbk-mb-6"
              separatorClass={"tbk--mt-1.5 lg:tbk--mt-2"}
            >
              Benefits
            </HeadlineWithSeparator>
          </div>

          <div className="tbk-mb-4 tbk-flex tbk-justify-center">
            <div className="tbk-max-w-full tbk-overflow-hidden tbk-px-2 lg:tbk-px-2">
              <div className="tb-slider tbk-flex-nowrap tbk-gap-2 lg:tbk-grid-cols-4 lg:tbk-gap-2 xl:tbk-grid">
                <BenefitCard
                  imgs={[benefitsExclusiveVenues, benefitsExclusiveVenues2x]}
                  title={"Exclusive Venues"}
                />
                <BenefitCard
                  imgs={[
                    benefitsClassesByTopCoaches,
                    benefitsClassesByTopCoaches2x,
                  ]}
                  title={
                    <span>
                      Classes by
                      <br />
                      Top TruBe Coaches
                    </span>
                  }
                />
                <BenefitCard
                  imgs={[
                    benefitsBrandCollabsGifts,
                    benefitsBrandCollabsGifts2x,
                  ]}
                  title={
                    <span>
                      Brand
                      <br />
                      Collaborations
                      <br />& Gifts
                    </span>
                  }
                />
                <BenefitCard
                  imgs={[
                    benefitsPostWorkoutBrandActivations,
                    benefitsPostWorkoutBrandActivations2x,
                  ]}
                  title={
                    <span>
                      Post Workout
                      <br />
                      Brand Activations
                    </span>
                  }
                />
              </div>
            </div>
          </div>

          <div className="tbk-relative tbk-pb-7 tbk-text-center lg:tbk-pb-7">
            <Link
              to={{
                pathname: isUserLoggedIn ? `/checkout` : `/become-a-member`,
                search: isUserLoggedIn
                  ? "?membershipId=1"
                  : "?return_to=/checkout?membershipId=1",
                state: { from: location.pathname },
              }}
              className="tbk-inline-block"
            >
              <Button
                variant={"secondary"}
                className="tbk-w-64"
                endIcon={<ArrowRightIcon className="tbk-ml-0.5" />}
              >
                Explore All Benefits
              </Button>
            </Link>
          </div>
        </div>
      ) : null}

      {!customerMembership ? (
        <section className="tbk-mb-0 tbk-pt-7 tbk-pb-7 lg:tbk-pt-9 lg:tbk-pb-7">
          <div className="tbk-container tbk-mx-auto tbk-px-3 lg:tbk-px-8">
            <HeadlineWithSeparator
              component="h3"
              titleClass="tbk-bg-basic-white tbk-text-blue-grey tbk-text-h3-subtitle"
              className="tbk-mb-5 lg:tbk-mb-6"
              separatorClass={"tbk--mt-1.5 lg:tbk--mt-2"}
            >
              The Venues
            </HeadlineWithSeparator>
            {/*<div className="tbk-relative tbk-mb-5 tbk-text-center lg:tbk-mb-6">*/}
            {/*  <h3 className="tbk-text-h3-subtitle lg:tbk-text-title-bold tbk-inline-block tbk-bg-basic-white tbk-px-2 tbk-text-center tbk-text-blue-grey">*/}
            {/*    The Venues*/}
            {/*  </h3>*/}
            {/*  <hr className="tbk-mb-0 tbk--mt-1.5 tbk-border-blue-grey-light lg:tbk--mt-2" />*/}
            {/*</div>*/}
          </div>

          <LogoBar />

          <h3 className="tbk-text-h3-subtitle tbk-text-center tbk-text-blue-grey">
            And Many Others...
          </h3>
        </section>
      ) : null}

      {false && !customerMembership ? (
        <section className="lifestyle-benefits tbk-mb-0 tbk-pt-4 tbk-pb-4 lg:tbk-pt-9 lg:tbk-pb-7">
          <div className="tbk-container tbk-mx-auto tbk-px-3 lg:tbk-px-8">
            <HeadlineWithSeparator
              component="h3"
              titleClass="tbk-bg-basic-white tbk-text-blue-grey tbk-text-h3-subtitle lg:tbk-text-title-bold"
              className="tbk-mb-5 lg:tbk-mb-6"
              separatorClass={"tbk--mt-1.5 lg:tbk--mt-2"}
            >
              Benefits
            </HeadlineWithSeparator>

            <div className="">
              <div className="tbk-grid tbk-grid-cols-lifestyle-benefits">
                <div className="tbk-rounded-tl-2xl tbk-border tbk-border-blue-grey-light tbk-p-1 lg:tbk-pt-2 lg:tbk-pb-2 lg:tbk-pl-4 lg:tbk-pr-4">
                  <div className="tbk-text-small lg:tbk-text-main tbk-text-primary">
                    Access 3 exclusive wellness classes/month
                  </div>
                </div>
                <div className="tbk-flex tbk-items-center tbk-justify-center tbk-rounded-tr-2xl tbk-border-t tbk-border-r tbk-border-b tbk-border-blue-grey-light tbk-pl-3 tbk-pr-3 lg:tbk-pl-6 lg:tbk-pr-6">
                  <CheckboxOIcon
                    width={
                      clientWidth() < theme.responsive.breakpoints.lg ? 16 : 33
                    }
                    height={
                      clientWidth() < theme.responsive.breakpoints.lg ? 16 : 32
                    }
                    className="tbk-text-green"
                  />
                </div>
              </div>

              <div className="tbk-grid tbk-grid-cols-lifestyle-benefits">
                <div className="tbk-border-l tbk-border-r tbk-border-b tbk-border-blue-grey-light tbk-p-1 lg:tbk-pt-2 lg:tbk-pb-2 lg:tbk-pl-4 lg:tbk-pr-4">
                  <div className="tbk-text-small lg:tbk-text-main tbk-text-primary">
                    Enjoy unique brand collaborations & gifts
                  </div>
                </div>
                <div className="tbk-border- tbk-flex tbk-items-center tbk-justify-center tbk-border-r tbk-border-b tbk-border-blue-grey-light tbk-pl-3 tbk-pr-3 lg:tbk-pl-6 lg:tbk-pr-6">
                  <CheckboxOIcon
                    width={
                      clientWidth() < theme.responsive.breakpoints.lg ? 16 : 33
                    }
                    height={
                      clientWidth() < theme.responsive.breakpoints.lg ? 16 : 32
                    }
                    className="tbk-text-green"
                  />
                </div>
              </div>

              <div className="tbk-grid tbk-grid-cols-lifestyle-benefits">
                <div className="tbk-border-l tbk-border-r tbk-border-b tbk-border-blue-grey-light tbk-p-1 lg:tbk-pt-2 lg:tbk-pb-2 lg:tbk-pl-4 lg:tbk-pr-4">
                  <div className="tbk-text-small lg:tbk-text-main tbk-text-primary">
                    Get complimentary swag bags
                  </div>
                </div>
                <div className="tbk-border- tbk-flex tbk-items-center tbk-justify-center tbk-border-r tbk-border-b tbk-border-blue-grey-light tbk-pl-3 tbk-pr-3 lg:tbk-pl-6 lg:tbk-pr-6">
                  <CheckboxOIcon
                    width={
                      clientWidth() < theme.responsive.breakpoints.lg ? 16 : 33
                    }
                    height={
                      clientWidth() < theme.responsive.breakpoints.lg ? 16 : 32
                    }
                    className="tbk-text-green"
                  />
                </div>
              </div>

              <div className="tbk-grid tbk-grid-cols-lifestyle-benefits">
                <div className="tbk-border-l tbk-border-r tbk-border-b tbk-border-blue-grey-light tbk-p-1 lg:tbk-pt-2 lg:tbk-pb-2 lg:tbk-pl-4 lg:tbk-pr-4">
                  <div className="tbk-text-small lg:tbk-text-main tbk-text-primary">
                    Classes led by top TruBe coaches
                  </div>
                </div>
                <div className="tbk-border- tbk-flex tbk-items-center tbk-justify-center tbk-border-r tbk-border-b tbk-border-blue-grey-light tbk-pl-3 tbk-pr-3 lg:tbk-pl-6 lg:tbk-pr-6">
                  <CheckboxOIcon
                    width={
                      clientWidth() < theme.responsive.breakpoints.lg ? 16 : 33
                    }
                    height={
                      clientWidth() < theme.responsive.breakpoints.lg ? 16 : 32
                    }
                    className="tbk-text-green"
                  />
                </div>
              </div>

              <div className="tbk-grid tbk-grid-cols-lifestyle-benefits">
                <div className="tbk-border-l tbk-border-r tbk-border-b tbk-border-blue-grey-light tbk-p-1 lg:tbk-pt-2 lg:tbk-pb-2 lg:tbk-pl-4 lg:tbk-pr-4">
                  <div className="tbk-text-small lg:tbk-text-main tbk-text-primary">
                    Modalities: HIIT, Pilates, Yoga, Meditation & more
                  </div>
                </div>
                <div className="tbk-border- tbk-flex tbk-items-center tbk-justify-center tbk-border-r tbk-border-b tbk-border-blue-grey-light tbk-pl-3 tbk-pr-3 lg:tbk-pl-6 lg:tbk-pr-6">
                  <CheckboxOIcon
                    width={
                      clientWidth() < theme.responsive.breakpoints.lg ? 16 : 33
                    }
                    height={
                      clientWidth() < theme.responsive.breakpoints.lg ? 16 : 32
                    }
                    className="tbk-text-green"
                  />
                </div>
              </div>

              <div className="tbk-grid tbk-grid-cols-lifestyle-benefits">
                <div className="tbk-border-l tbk-border-r tbk-border-b tbk-border-blue-grey-light tbk-p-1 lg:tbk-pt-2 lg:tbk-pb-2 lg:tbk-pl-4 lg:tbk-pr-4">
                  <div className="tbk-text-small lg:tbk-text-main tbk-text-primary">
                    Exclusive brand partner discounts
                  </div>
                </div>
                <div className="tbk-border- tbk-flex tbk-items-center tbk-justify-center tbk-border-r tbk-border-b tbk-border-blue-grey-light tbk-pl-3 tbk-pr-3 lg:tbk-pl-6 lg:tbk-pr-6">
                  <CheckboxOIcon
                    width={
                      clientWidth() < theme.responsive.breakpoints.lg ? 16 : 33
                    }
                    height={
                      clientWidth() < theme.responsive.breakpoints.lg ? 16 : 32
                    }
                    className="tbk-text-green"
                  />
                </div>
              </div>

              <div className="tbk-grid tbk-grid-cols-lifestyle-benefits">
                <div className="tbk-border-l tbk-border-r tbk-border-b tbk-border-blue-grey-light tbk-p-1 lg:tbk-pt-2 lg:tbk-pb-2 lg:tbk-pl-4 lg:tbk-pr-4">
                  <div className="tbk-text-small lg:tbk-text-main tbk-text-primary">
                    Build community connections
                  </div>
                </div>
                <div className="tbk-border- tbk-flex tbk-items-center tbk-justify-center tbk-border-r tbk-border-b tbk-border-blue-grey-light tbk-pl-3 tbk-pr-3 lg:tbk-pl-6 lg:tbk-pr-6">
                  <CheckboxOIcon
                    width={
                      clientWidth() < theme.responsive.breakpoints.lg ? 16 : 33
                    }
                    height={
                      clientWidth() < theme.responsive.breakpoints.lg ? 16 : 32
                    }
                    className="tbk-text-green"
                  />
                </div>
              </div>

              <div className="tbk-grid tbk-grid-cols-lifestyle-benefits">
                <div className="tbk-border-l tbk-border-r tbk-border-b tbk-border-blue-grey-light tbk-p-1 lg:tbk-pt-2 lg:tbk-pb-2 lg:tbk-pl-4 lg:tbk-pr-4">
                  <div className="tbk-text-small lg:tbk-text-main tbk-text-primary">
                    Forge meaningful relationships
                  </div>
                </div>
                <div className="tbk-border- tbk-flex tbk-items-center tbk-justify-center tbk-border-r tbk-border-b tbk-border-blue-grey-light tbk-pl-3 tbk-pr-3 lg:tbk-pl-6 lg:tbk-pr-6">
                  <CheckboxOIcon
                    width={
                      clientWidth() < theme.responsive.breakpoints.lg ? 16 : 33
                    }
                    height={
                      clientWidth() < theme.responsive.breakpoints.lg ? 16 : 32
                    }
                    className="tbk-text-green"
                  />
                </div>
              </div>

              <div className="tbk-grid tbk-grid-cols-lifestyle-benefits">
                <div className="tbk-border-l tbk-border-r tbk-border-b tbk-border-blue-grey-light tbk-p-1 lg:tbk-pt-2 lg:tbk-pb-2 lg:tbk-pl-4 lg:tbk-pr-4">
                  <div className="tbk-text-small lg:tbk-text-main tbk-text-primary">
                    Cancel anytime
                  </div>
                </div>
                <div className="tbk-border- tbk-flex tbk-items-center tbk-justify-center tbk-border-r tbk-border-b tbk-border-blue-grey-light tbk-pl-3 tbk-pr-3 lg:tbk-pl-6 lg:tbk-pr-6">
                  <CheckboxOIcon
                    width={
                      clientWidth() < theme.responsive.breakpoints.lg ? 16 : 33
                    }
                    height={
                      clientWidth() < theme.responsive.breakpoints.lg ? 16 : 32
                    }
                    className="tbk-text-green"
                  />
                </div>
              </div>

              <div className="tbk-grid tbk-grid-cols-lifestyle-benefits">
                <div className="tbk-rounded-bl-2xl tbk-border-l tbk-border-r tbk-border-b tbk-border-blue-grey-light tbk-p-1 lg:tbk-pt-2 lg:tbk-pb-2 lg:tbk-pl-4 lg:tbk-pr-4">
                  <div className="tbk-text-small lg:tbk-text-main tbk-text-primary">
                    Membership: {membershipPrice}/month
                  </div>
                </div>
                <div className="tbk-flex tbk-items-center tbk-justify-center tbk-rounded-br-2xl tbk-border-r tbk-border-b tbk-border-blue-grey-light tbk-pl-3 tbk-pr-3 lg:tbk-pl-6 lg:tbk-pr-6">
                  <CheckboxOIcon
                    width={
                      clientWidth() < theme.responsive.breakpoints.lg ? 16 : 33
                    }
                    height={
                      clientWidth() < theme.responsive.breakpoints.lg ? 16 : 32
                    }
                    className="tbk-text-green"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {!customerMembership && false ? (
        <div className="tbk-flex tbk-flex-col tbk-items-center tbk-justify-center tbk-overflow-hidden tbk-pt-7 tbk-pb-7 lg:tbk-pt-9 lg:tbk-pb-9">
          <div className="tbk-container tbk-px-3 lg:tbk-px-8">
            <div
              ref={ytSliderRef}
              className={`tbk-relative tbk-flex tbk-w-full tbk-flex-col tbk-justify-center md:tbk-h-136 lg:tbk-m-0 `}
            >
              {slickInitialised && (
                <Slider
                  className="events-videos-carousel tbk-pb-1 tbk-text-center"
                  dots={true}
                  dotsClass={
                    "slick-dots !tbk-inline-block tbk--translate-x-1/2 tbk-left-1/2"
                  }
                  arrows={clientWidth() > theme.responsive.breakpoints.lg}
                  autoplay={false}
                  // autoplaySpeed={4000}
                  pauseOnFocus
                  pauseOnDotsHover
                  pauseOnHover
                  infinite={true}
                  speed={500}
                  slidesToShow={1}
                  slidesToScroll={1}
                  initialSlide={0}
                  responsive={sliderResponsiveSettings}
                  nextArrow={
                    <SliderNextArrow buttonClassName="tbk-h-[48px] tbk-w-[48px]" />
                  }
                  prevArrow={
                    <SliderPrevArrow buttonClassName="tbk-h-[48px] tbk-w-[48px]" />
                  }
                  afterChange={() => {
                    const ytPlayerIframes =
                      document.querySelectorAll(".yt_player_iframe");

                    [].forEach.call(ytPlayerIframes, function (iframe) {
                      iframe.contentWindow.postMessage(
                        '{"event":"command","func":"stopVideo","args":""}',
                        "*"
                      );
                    });
                  }}
                  onSwipe={handleSlideSwipe}
                >
                  {pastEventsVideos.map((val, index) => {
                    return (
                      <div
                        id={`slide-${index}`}
                        className="slide partner-review-item"
                        key={index}
                        onClick={() => openGalleryCarouselModal(index)}
                      >
                        <div className="content slide-bg tbk-h-full tbk-w-full">
                          <div
                          // className="tb-image-container tbk-relative tbk-h-full tbk-overflow-hidden tbk-pt-100 lg:tbk-pt-full"
                          >
                            <iframe
                              className="yt_player_iframe tbk-overflow-hidden tbk-rounded-2xl"
                              // width={(height * 16) / 9}
                              // height={height - 128}
                              width={"100%"}
                              height={height}
                              src={val}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              referrerPolicy="strict-origin-when-cross-origin"
                              allowFullScreen
                              // title="TruBe x Pendry | Wellness Day Recap"
                            >
                              >
                            </iframe>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              )}
            </div>
          </div>
        </div>
      ) : null}

      {!customerMembership && false ? (
        <div className="tbk-flex tbk-flex-col tbk-items-center tbk-justify-center tbk-pt-7 tbk-pb-7 lg:tbk-pt-7 lg:tbk-pb-9">
          <div className="tbk-container tbk-px-2 lg:tbk-px-8">
            <div className="lg:tbk-grid lg:tbk-grid-cols-2 lg:tbk-gap-8 xl:tbk-grid-cols-lp-cols-layout">
              <div className="tbk-mb-5 lg:tbk-mb-0">
                <h2 className="tbk-text-h2-black lg:tbk-text-epic-medium tbk-mb-2 !tbk-leading-tight tbk-text-primary lg:tbk-mb-4 lg:!tbk-leading-none">
                  Connect, Inspire, Flourish: Join TruBe's Wellness Community
                </h2>
                <p className="tbk-text-main tbk-mb-3 tbk-text-primary lg:tbk-mb-7">
                  Step into the TruBe lifestyle and unlock curated wellness
                  classes that go beyond the ordinary workout. Join our
                  community and discover weekly classes led by top coaches
                  across all modalities, including HIIT, Boxing, Pilates, Yoga,
                  Meditation, Sound Healing, and more. Held at exclusive venues,
                  each session offers pre and post brand activations with select
                  partners. Immerse yourself in a supportive environment,
                  connect with like-minded individuals, and forge meaningful
                  relationships while prioritizing your well-being.
                </p>

                <div className="tbk-text-center lg:tbk-text-left">
                  <Link
                    to={{
                      pathname: isUserLoggedIn
                        ? `/checkout`
                        : `/become-a-member`,
                      search: isUserLoggedIn
                        ? "?membershipId=1"
                        : "?return_to=/checkout?membershipId=1",
                      state: { from: location.pathname },
                    }}
                    // href="https://trubeapp.com/app-redirects/deeplink-redirect-events.html"
                    // target="_blank"
                  >
                    <Button
                      size={"md"}
                      className="tbk-mt-3 tbk-mb-3 tbk-inline-flex tbk-pl-5 tbk-pr-5 lg:tbk-mt-0 lg:tbk-mb-0"
                      endIcon={<ArrowRightIcon className="tbk-ml-0.5" />}
                    >
                      {isUserLoggedIn && false
                        ? "Explore All Coaches"
                        : "Join the Community"}
                    </Button>
                  </Link>
                </div>
              </div>
              <div>
                <div className="tbk-relative tbk-overflow-hidden tbk-rounded-2xl lg:tbk-h-auto lg:tbk-bg-none">
                  <div
                    className={`media-slider no-gradient tbk-h-92 md:tbk-h-[470px] ${
                      !_partnerMedia || _partnerMedia.length <= 1
                        ? "no-media"
                        : ""
                    } ${
                      !trainerPictureUrl ? "tbk-justify-center" : ""
                    } tbk-relative tbk-flex tbk-h-full tbk-w-full tbk-flex-col tbk-overflow-hidden lg:tbk-m-0 lg:tbk-h-108 lg:tbk-w-108 lg:tbk-rounded-2xl`}
                  >
                    {slickInitialised &&
                    _partnerMedia &&
                    _partnerMedia.length > 1 ? (
                      <Slider
                        className="coach-media-carousel"
                        dots={true}
                        dotsClass={"slick-dots"}
                        arrows={clientWidth() > theme.responsive.breakpoints.lg}
                        autoplay={autoPlay}
                        autoplaySpeed={4000}
                        pauseOnFocus
                        pauseOnDotsHover
                        pauseOnHover
                        infinite={true}
                        speed={500}
                        slidesToShow={1}
                        slidesToScroll={1}
                        initialSlide={0}
                        responsive={sliderResponsiveSettings}
                        nextArrow={<SliderNextArrow className="" />}
                        prevArrow={<SliderPrevArrow className="" />}
                        onSwipe={handleSlideSwipe}
                      >
                        {_partnerMedia
                          .filter((val) => val.type === "IMAGE")
                          .map((val, index) => {
                            return (
                              <div
                                id={`slide-${index}`}
                                className="slide partner-review-item !tbk-block"
                                key={index}
                                onClick={() =>
                                  openGalleryCarouselModal(index, true)
                                }
                              >
                                <div className="content slide-bg tbk-h-full tbk-w-full">
                                  <div
                                    className="tb-image-container tbk-relative tbk-h-full tbk-overflow-hidden tbk-pt-100 lg:tbk-pt-full"
                                    style={
                                      {
                                        // paddingTop: "100%",
                                      }
                                    }
                                  >
                                    <img
                                      className="tbk-absolute tbk-top-0 tbk-h-full tbk-w-full tbk-object-cover lg:tbk-rounded-2xl"
                                      itemProp="image"
                                      src={val.url}
                                      alt={`${"Personal Trainer in Los Angele"}`}
                                    />
                                  </div>
                                </div>
                                {/*{index !== partnerReviews.length - 1 ? <hr /> : null}*/}
                              </div>
                            );
                          })}
                      </Slider>
                    ) : trainerPictureUrl ? (
                      <div
                        className="tb-image-container tbk-relative tbk-overflow-hidden tbk-pt-100 lg:tbk-pt-full"
                        style={
                          {
                            // paddingTop: "100%",
                          }
                        }
                      >
                        <img
                          className="tbk-absolute tbk-top-0 tbk-h-full tbk-w-full tbk-object-cover lg:tbk-rounded-2xl"
                          itemProp="image"
                          src={
                            trainerPictureUrl
                              ? trainerPictureUrl
                              : defaultPartnerPhoto
                          }
                          alt={` | Personal Trainer in Los Angeles`}
                        />
                      </div>
                    ) : (
                      <div className="tbk-py-5 sm:tbk-mt-[10px]">
                        <Lottie
                          options={defaultOptions}
                          height={
                            clientWidth() < theme.responsive.breakpoints.sm
                              ? 116
                              : 112
                          }
                          width={
                            clientWidth() < theme.responsive.breakpoints.sm
                              ? 116
                              : 112
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {offers.length ? (
        <div className="tbk-flex tbk-flex-col tbk-items-center tbk-justify-center tbk-overflow-hidden tbk-pt-4 tbk-pb-4 lg:tbk-pb-7 lg:tbk-pt-7">
          <div className="tbk-container tbk-px-3 lg:tbk-px-8">
            <TitleWithSeparator
              className="tbk-mb-3"
              titleClass="tbk-text-center tbk-bg-basic-white tbk-text-blue-grey tbk-text-h3-subtitle lg:tbk-text-title-bold"
              separatorClass="tbk-border-blue-grey-light"
            >
              Exclusive Member Discounts
              <br className="md:tbk-hidden" /> on Top Brands
            </TitleWithSeparator>

            {slickInitialised && (
              <Slider
                className="offers-carousel slick-slider-shadow-fix tbk--ml-1 tbk--mr-1 tbk-pb-3 tbk-text-center"
                dots={true}
                dotsClass={
                  "slick-dots !tbk-inline-block tbk--translate-x-1/2 tbk-left-1/2 tbk-whitespace-nowrap"
                }
                arrows={clientWidth() > theme.responsive.breakpoints.lg}
                autoplay={autoPlay}
                autoplaySpeed={4000}
                pauseOnFocus
                pauseOnDotsHover
                pauseOnHover
                infinite={offers.length > 1}
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                initialSlide={0}
                responsive={offersSliderResponsiveSettings}
                nextArrow={
                  <SliderNextArrow buttonClassName="tbk-h-[48px] tbk-w-[48px]" />
                }
                prevArrow={
                  <SliderPrevArrow buttonClassName="tbk-h-[48px] tbk-w-[48px]" />
                }
                // onSwipe={handleSlideSwipe}
              >
                {offers.map((offer, index) => (
                  <div
                    //id={`slide-${index}`}
                    className="slide offer tbk-pl-1 tbk-pr-1"
                    key={index}
                    // onClick={() => openGalleryCarouselModal(index)}
                  >
                    <div className="content slide-bg tbk-h-full tbk-w-full">
                      <Link
                        to={`/offers/${offer.id}`}
                        // target="_blank"
                      >
                        <OfferCard
                          imgUrl={offer.coverPhoto}
                          logoUrl={offer.logo}
                          title={offer.title}
                          subtitle={offer.subtitle}
                          // place={offer.location.name}
                          status={offer.status}
                        />
                      </Link>
                    </div>
                  </div>
                ))}
              </Slider>
            )}

            {/*<div className="tb-slider tb-past-events-slider tbk-flex-nowrap tbk-gap-2 md:tbk-grid-cols-2 lg:tbk-grid lg:tbk-grid-cols-4">*/}

            {/*</div>*/}
          </div>
        </div>
      ) : null}

      {customerMembership && completedEvents.length ? (
        <div className="tbk-flex tbk-flex-col tbk-items-center tbk-justify-center tbk-overflow-hidden tbk-pt-3 tbk-pb-4 lg:tbk-pb-7 lg:tbk-pt-9">
          <div className="tbk-container tbk-px-3 lg:tbk-px-8">
            <HeadlineWithSeparator
              component="h3"
              titleClass="tbk-bg-basic-white tbk-text-blue-grey tbk-text-h3-subtitle lg:tbk-text-title-bold"
              className="tbk-mt-4 tbk-mb-6 lg:tbk-mt-0"
              separatorClass={"tbk--mt-1.5 lg:tbk--mt-2"}
            >
              Previous Classes
            </HeadlineWithSeparator>

            <div className="tb-slider tb-past-events-slider tbk-flex-nowrap tbk-gap-2 md:tbk-grid-cols-2 lg:tbk-grid lg:tbk-grid-cols-4">
              {completedEvents.map((evt) => (
                <Link
                  key={evt.id}
                  to={`/events/${evt.id}`}
                  // href={`https://trubeapp.com/app-redirects/deeplink-redirect-events.html?id=${event.id}`}
                  // target="_blank"
                  onClick={clearCompletedEventsLoaded}
                >
                  <div className="tb-slide tbk-mb-5 tbk-grow tbk-basis-72 lg:tbk-mb-0">
                    <div className="tbk-relative tbk-overflow-hidden tbk-rounded-2xl">
                      <img
                        className="tbk-h-52 tbk-w-72 tbk-w-full tbk-min-w-[208px] tbk-object-cover lg:tbk-h-66 xl:tbk-min-w-[268px]"
                        src={evt.pastPhoto}
                        //srcSet={`${access2xImg} 2x, ${access3xImg} 3x`}
                        alt="Access"
                      />
                      {/*  <HowItWorks1 className="tbk-absolute tbk-top-1/2 tbk-left-1/2 tbk--translate-x-1/2 tbk--translate-y-1/2" />*/}
                      {/*  <div className="tbk-text-title-bold tbk-absolute tbk-top-1/2 tbk-left-1/2 tbk--translate-x-1/2 tbk--translate-y-1/2 tbk-text-center tbk-text-basic-white">*/}
                      {/*    Access*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                      {/*<div className="tbk-text-h3-subtitle tbk-px-3 tbk-pt-2 tbk-pb-1 tbk-text-center tbk-text-primary">*/}
                      {/*  Access and book the best coaches near you*/}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
            <div className="tbk-relative tbk-mt-4 tbk-text-center lg:tbk-mt-4">
              <Link to={`/events/previous`} className="tbk-inline-block">
                <Button
                  size={"md"}
                  className="tbk-w-64"
                  endIcon={<ArrowRightIcon />}
                  onClick={clearCompletedEventsLoaded}
                >
                  View All Previous
                </Button>
              </Link>
            </div>
          </div>
        </div>
      ) : null}

      <div
        id="faq"
        ref={questionsSectionRef}
        className="tbk-relative tbk-bg-basic-white tbk-pt-7 tbk-pb-7 lg:tbk-pt-9 lg:tbk-pb-0"
      >
        <div className="tbk-container tbk-z-1 tbk-mx-auto tbk-px-2 lg:tbk-px-8 lg:tbk-pb-9">
          <TitleWithSeparator
            className="tbk-mb-4"
            titleClass="tbk-text-center tbk-text-blue-grey tbk-text-h3-subtitle lg:tbk-text-title-bold"
            separatorClass="tbk-border-blue-grey-light"
          >
            Any Questions?
          </TitleWithSeparator>

          <div className="tbk-flex tbk-flex-col-reverse md:tbk-grid md:tbk-grid-cols-homepage-two-cols-layout md:tbk-items-start md:tbk-gap-8">
            <div className="tbk-flex tbk-flex-col tbk-items-center tbk-rounded-2xl tbk-bg-grey-light tbk-p-3">
              <img src={askConcierge} alt="Ask TruBe Concierge" />
              <p className="tbk-text-epic-medium tbk-my-4 tbk-text-center !tbk-leading-none tbk-text-primary">
                We're here
                <br /> to <span className="tbk-text-coral">help</span>
              </p>
              <Button
                size="md"
                className="tbk-w-68 lg:tbk-w-full"
                endIcon={<ArrowRightIcon />}
                onClick={() => setIsAskConciergeModalOpen(true)}
              >
                Ask TruBe Concierge
              </Button>
            </div>

            <div className="tbk-mb-5 tbk-text-center lg:tbk-mb-0">
              <AccordionItem
                className=""
                title={"What is the TruBe Lifestyle membership?"}
                text={`The TruBe Lifestyle membership is a subscription-based service that grants you access to curated wellness classes designed to enhance your overall well-being. For a monthly fee of ${membershipPrice}, you can attend up to 3 classes per month, each focusing on various TruBe offerings such as HIIT, Mobility, Pilates, Yoga, Meditation, Sound Healing, and more.`}
              />
              <AccordionItem
                className="tbk-mt-3"
                title={"What can I expect from TruBe Lifestyle classes?"}
                text={
                  "TruBe Lifestyle classes present an array of activities guided by leading coaches spanning various wellness modalities. Whether you seek an invigorating HIIT session or serene meditation and sound healing, our classes accommodate all preferences and fitness levels. Held at exclusive venues and led by top TruBe coaches, each class includes pre and post-session brand classes and curated TruBe swag bags."
                }
              />
              <AccordionItem
                className="tbk-mt-3"
                title={"How do I sign up for the TruBe Lifestyle membership?"}
                text={
                  "Signing up for the TruBe Lifestyle membership is easy! Simply download the TruBe app, click on the TruBe lifestyle option, and choose the TruBe subscription membership plan. Once subscribed, you'll gain instant access to our complete class calendar and can start RSVPing for your preferred classes right away."
                }
              />
              <AccordionItem
                className="tbk-mt-3"
                title={
                  "Can I attend classes at exclusive venues with the TruBe Lifestyle membership?"
                }
                text={
                  "Absolutely! TruBe Lifestyle classes are held at exclusive venues to provide you with a premium wellness experience. From chic studios to tranquil outdoor settings, each venue is carefully selected to elevate your overall event class."
                }
              />
              {showMoreQuestions ? (
                <AccordionItem
                  className="tbk-mt-3"
                  title={"How do I RSVP for TruBe Lifestyle classes?"}
                  text={
                    "To RSVP for TruBe Lifestyle classes, simply browse through our complete class calendar on the TruBe app and select the classes you wish to attend. Once you've made your selections, click on the RSVP button, and your ticket(s) will be automatically added to your Apple Wallet upon confirmation. All classes are subject to availability."
                  }
                />
              ) : null}
              {showMoreQuestions ? (
                <AccordionItem
                  className="tbk-mt-3"
                  title={"What if I can't attend all 3 classes in a month?"}
                  text={
                    "We understand that life can get busy, and you may not always be able to attend all 3 classes in a month. That's why your TruBe Lifestyle membership allows you the flexibility to attend up to 3 classes per month, giving you the freedom to prioritize your well-being at your own pace."
                  }
                />
              ) : null}
              {showMoreQuestions ? (
                <AccordionItem
                  className="tbk-mt-3"
                  title={
                    "How can I connect with other members of the TruBe community?"
                  }
                  text={
                    "The TruBe Lifestyle membership not only offers you access to curated wellness classes but also provides you with an opportunity to connect with like-minded individuals who share your passion for health and wellness. Whether it's striking up a conversation with fellow attendees at a class or joining our online community forums, there are plenty of ways to forge meaningful connections within the TruBe community."
                  }
                />
              ) : null}
              {showMoreQuestions ? (
                <AccordionItem
                  className="tbk-mt-3"
                  title={"How can I cancel my TruBe Lifestyle membership?"}
                  text={
                    "To cancel your TruBe Lifestyle membership, simply log in to your TruBe account on the app or website and navigate to the subscription settings. From there, you'll find the option to cancel your membership."
                  }
                />
              ) : null}

              {showMoreQuestions ? (
                <AccordionItem
                  className="tbk-mt-3"
                  title={
                    "What happens if I don’t cancel my RSVP 12 hours before a class?"
                  }
                  text={`If you cancel your RSVP less than 12 hours before a class or fail to show up, you will be charged a ${formatCurrency(
                    10
                  )} penalty.`}
                />
              ) : null}

              {/*{showMoreQuestions ? (*/}
              {/*  <AccordionItem*/}
              {/*    className="tbk-mt-3"*/}
              {/*    title={"Questions?"}*/}
              {/*    text={*/}
              {/*      <p>*/}
              {/*        Email us anytime at{" "}*/}
              {/*        <a href="mailto:members@trubeapp.com">*/}
              {/*          members@trubeapp.com*/}
              {/*        </a>*/}
              {/*        . We’re here to help!*/}
              {/*      </p>*/}
              {/*    }*/}
              {/*  />*/}
              {/*) : null}*/}

              {!showMoreQuestions ? (
                <button
                  className="tbk-text-main tbk-mt-1 tbk-mb-1 tbk-inline-flex tbk-items-center tbk-text-blue-grey tbk-underline lg:tbk-mb-0"
                  onClick={handleMoreQuestionsClick}
                >
                  <div className="">More Questions</div>
                  <CaretDownIcon />
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {!customerMembership ? <TruBeInPress /> : null}

      <AppBanner />

      <Footer />

      <AskConciergeModal
        isOpen={isAskConciergeModalOpen}
        onClose={() => setIsAskConciergeModalOpen(false)}
      />

      <ManageMembershipModal
        isOpen={isManageMembershipModalOpen}
        showCta={!hasMembershipBeenCancelled}
        membershipInfo={customerMembership}
        region={user ? user.region : region /*process.env.REACT_APP_REGION*/}
        onCancelMembershipClick={openCancelSubscriptionModal}
        onPauseMembershipClick={openPauseSubscriptionModal}
        onClose={() => setIsManageMembershipModalOpen(false)}
      />

      <Modal
        isOpen={isGalleryCarouselModalOpen}
        // onAfterOpen={this.afterOpenModal}
        onRequestClose={closeGalleryCarouselModal.bind(null, true)}
        style={modalStyles.galleryCarouselDefault}
        contentLabel="Gallery Carousel"
      >
        {/*<Link role='button' onClick={this.closeDateTimeSelectorModal}><Icon name='close' className='tb-pd-0' width='32px' height='32px' /></Link>*/}
        <div
          role="button"
          className="tbk-absolute tbk-top-2 tbk-right-2 tbk-z-10 tbk-p-1"
          onClick={closeGalleryCarouselModal}
        >
          <CloseIcon className="tbk-p-0 tbk-text-basic-white" />
        </div>
        <div className="modal-gallery-carousel u-d-flex_ u-flex-column_ tbk-h-full">
          <Carousel
            // images={images}
            hasIndexBoard={false}
            hasMediaButton={false}
            hasSizeButton={false}
            hasThumbnails={false}
            index={galleryInitialSlide}
            leftIcon={
              <svg
                width="8"
                height="13"
                viewBox="0 0 8 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 1.5L6.5 0L0 6.5L6.5 13L8 11.5L3 6.5L8 1.5Z"
                  fill="#CBCFD5"
                />
              </svg>
            }
            rightIcon={
              <svg
                width="8"
                height="13"
                viewBox="0 0 8 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 1.5L1.5 0L8 6.5L1.5 13L0 11.5L5 6.5L0 1.5Z"
                  fill="#CBCFD5"
                />
              </svg>
            }
            style={{ height: "100%", width: "100%" }}
            // onIndexChange={({ curIndex, curIndexForDisplay }) => {
            //   const ytPlayerIframes = document.querySelectorAll(".yt_player_iframe");
            // }}
          >
            {galleryImages &&
              galleryImages.map((url) => (
                <figure className="_3iVQ0">
                  <img
                    className="_t1897 tbk-h-auto_ tbk-top-1/2 tbk-mx-auto tbk-w-auto tbk--translate-y-1/2 tbk-object-contain"
                    src={url}
                  />
                </figure>
              ))}
          </Carousel>
        </div>
      </Modal>
      <CancelMembershipModal
        open={isCancelSubscriptionModalOpen}
        isTrial={customerMembership && customerMembership.status === "TRIALING"}
        region={user ? user.region : region /*process.env.REACT_APP_REGION*/}
        onClose={closeCancelSubscriptionModal}
        onMembershipCancel={handleMembershipCancel}
      />
      <PauseMembershipModal
        open={isPauseSubscriptionModalOpen}
        currentPeriodEnd={
          customerMembership && customerMembership.currentPeriodEnd
        }
        resumeAt={customerMembership && customerMembership.resumeAt}
        paused={customerMembership && customerMembership.isPaused}
        region={user ? user.region : region /*process.env.REACT_APP_REGION*/}
        onClose={closePauseSubscriptionModal}
        onMembershipPause={handleMembershipPause}
      />
      <Modal
        appElement={/*__CLIENT__ && */ document.getElementById("#app")}
        isOpen={isInfoModalOpen}
        // onAfterOpen={afterOpenInfoModal}
        onRequestClose={closeInfoModal}
        style={
          clientWidth() < theme.responsive.breakpoints.sm
            ? modalStyles.infoMobile
            : infoModalTitle === "Referral Bonus Applied"
            ? Object.assign(
                {},
                { ...modalStyles.info },
                {
                  content: {
                    ...modalStyles.info.content,
                    width: "320px",
                  },
                }
              )
            : modalStyles.info
        }
        contentLabel="Info Modal"
      >
        <div className="modal-info tbk-flex tbk-flex-col tbk-items-center">
          {infoModalTitle !== "Referral Bonus Applied" ? (
            <button
              onClick={closeInfoModal}
              className="tbk-absolute tbk-top-2 tbk-right-2 tbk-z-10 tbk-hidden tbk-text-coral"
            >
              <CloseIcon />
            </button>
          ) : null}
          {infoModalTitle === "Referral Bonus Applied" ? (
            <CheckNiceIcon size={64} className="tbk-text-green" />
          ) : null}
          <h3 className="tbk-text-title-bold tbk-mt-0 tbk-mb-1 tbk-text-center tbk-text-grey-main">
            {infoModalTitle}
          </h3>
          <p className="tbk-text-main tbk-mb-4 tbk-max-w-full tbk-text-center tbk-text-grey-main">
            {infoModalMsg}
          </p>
          {error && infoModalTitle !== "Referral Bonus Applied" ? (
            <BlockerIcon size={64} className="tbk-mb-4 tbk-text-green" />
          ) : (
            infoModalTitle !== "Referral Bonus Applied" && (
              <CheckNiceIcon size={64} className="tbk-mb-4 tbk-text-green" />
            )
          )}
          {infoModalTitle !== "Referral Bonus Applied" ? (
            <Button className="tbk-w-full" onClick={closeInfoModal}>
              Ok
            </Button>
          ) : null}
        </div>
      </Modal>
    </>
  );
}

const getMembershipSubscriptionItem = (membershipItems) => {
  if (!membershipItems || !membershipItems.length) {
    return;
  }

  return membershipItems.find(
    (val) => val.metadata.type === "EVENT_MEMBERSHIP"
  );
};
