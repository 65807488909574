import React from "react";

export default function MessageInfo({
  author,
  dateCreated,
  isLocalParticipant,
  isOnline,
}) {
  return (
    <div
      className={`tbk--mb-1 tbk-mt-1 tbk-flex tbk-items-center tbk-justify-center `}
    >
      {/*<div>{isLocalParticipant ? `${author} (You)` : author}</div>*/}
      <div className="tbk-text-extra-small tbk-rounded-lg tbk-bg-grey-light tbk-px-0.5 tbk-pt-[2px] tbk-pb-[2px] tbk-text-primary">
        {dateCreated}
      </div>
      {/*{isOnline ? <div>Online</div> : <div>Offline</div>}*/}
    </div>
  );
}
