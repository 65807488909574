import React, { useState, useCallback } from "react";
import Avatar from "../Avatar/Avatar";
import { TimetokenUtils } from "@pubnub/chat";

export default function ChatMenuItem({
  avatarUrl,
  text,
  latestMessage,
  present,
  avatarBubblePrecedent = "",
  count = "",
  markAsRead = false,
  markAsReadAction = (e) => {},
  setActiveChannel = () => {},
}) {
  const [showToolTip, setShowToolTip] = useState(false);

  const handleMouseEnter = (e) => {
    setShowToolTip(true);
  };
  const handleMouseLeave = (e) => {
    setShowToolTip(false);
  };

  const determineUserReadableDate = useCallback((timetoken) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const date = TimetokenUtils.timetokenToDate(timetoken);
    const datetime = `${days[date.getDay()]} ${date.getDate()} ${
      months[date.getMonth()]
    }`;

    return datetime;
  }, []);

  let time = "";

  if (latestMessage && latestMessage.message) {
    time = determineUserReadableDate(latestMessage.message.timetoken);
  }

  return (
    <div
      className="tbk-flex tbk-cursor-pointer tbk-flex-col"
      onClick={() => {
        setActiveChannel();
      }}
    >
      <div className="tbk-pl-4_ tbk-flex tbk-w-full tbk-flex-row tbk-items-center tbk-justify-between">
        <div className="tbk-gap-2_ tbk-flex tbk-h-6 tbk-min-w-0 tbk-grow tbk-flex-row tbk-items-center tbk-py-2 tbk-text-sm">
          <Avatar
            present={present}
            width={54}
            height={54}
            bubblePrecedent={avatarBubblePrecedent}
            avatarUrl={avatarUrl}
            border
          />
          <div className="tbk-ml-2 tbk-min-w-0 tbk-grow">
            <div className="tbk-items-center_ tbk-flex tbk-justify-between">
              <div className="tbk-text-h3-subtitle tbk-whitespace-nowrap tbk-text-primary">
                {text}
              </div>
              <div
                className="tbk-text-small tbk-inline-block tbk-whitespace-nowrap tbk-px-0.5 tbk-text-right tbk-text-blue-grey"
                style={{
                  paddingTop: "2px",
                  paddingBottom: "2px",
                }}
              >
                {time}
              </div>
            </div>
            <div
              className="tbk-text-small tbk-overflow-hidden tbk-text-ellipsis tbk-text-blue-grey"
              style={{
                maxHeight: "36px",
              }}
            >
              {latestMessage?.message?.text}
            </div>
          </div>
        </div>

        <div className="tbk-flex tbk-flex-row tbk-items-center">
          {/*<UnreadIndicator count={count} />*/}
          {false && markAsRead && (
            <div
              className={`tbk-m-3 tbk-h-4 tbk-w-4 tbk-cursor-pointer tbk-fill-current ${
                showToolTip ? "tbk-text-sky-700" : "tbk-text-sky-900"
              }`}
              onClick={(e) => markAsReadAction(e)}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className="tbk-relative">
                <div
                  className={`${
                    showToolTip ? "tbk-block" : "tbk-hidden"
                  } tbk-bottom-[0px]`}
                  //tip='Read'
                  //messageActionsTip={false}
                />
              </div>
              <svg viewBox="0 0 18 14">
                <path d="M5.79508 10.8749L1.62508 6.70492L0.205078 8.11492L5.79508 13.7049L17.7951 1.70492L16.3851 0.294922L5.79508 10.8749Z" />
              </svg>
            </div>
          )}
        </div>
      </div>
      <hr className="tbk-ml-7 tbk-mt-2 tbk-mb-2" />
    </div>
  );
}
