import React from "react";

export default function TruBeLogo({ className, width, height }) {
  return (
    <svg
      width={width || 70}
      height={height || 80}
      viewBox="0 0 70 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={
        className
          ? className + " tbk-min-w-0 tbk-shrink-0"
          : "tbk-min-w-0 tbk-shrink-0"
      }
      role="presentation"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.7001 13.3435C48.7445 20.7571 42.7475 26.8045 35.3038 26.8498C27.8558 26.8961 21.7859 20.9202 21.7405 13.5066C21.6961 6.0909 27.6942 0.0434186 35.14 0.000267426C42.5837 -0.0460412 48.6536 5.92777 48.7001 13.3435Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.0581 67.1637C69.0581 67.1637 23.7751 40.0631 4.46387 69.9384C4.46387 69.9384 31.719 94.3264 69.0581 67.1637Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.2076 30.772C42.2076 30.772 36.5693 49.9588 24.3983 49.9588C13.6593 49.9588 3.36808 26.8282 0.501953 26.6494C0.501953 26.6494 6.76963 19.0274 14.9128 24.3168C30.0049 34.1181 36.2092 33.2834 42.2076 30.772Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.6963 49.4056C58.8262 49.7208 85.6809 24.0188 57.0322 13.7551C54.1173 33.6785 39.9496 46.1878 37.6963 49.4056Z"
        fill="white"
      />
    </svg>
  );
}
