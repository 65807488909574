/* istanbul ignore file */
import React from "react";
import CaretDownIcon from "../../../../icons/CaretDownIcon"; //"@material-ui/icons/ArrowDownward";
import Button from "../../../../components/Button/Button"; //"@material-ui/core/Button";
import {
  enableBodyScroll,
  disableBodyScroll,
} from "../../../../lib/bodyScrollLock";

import { Message } from "@pubnub/chat";
import throttle from "lodash.throttle";
import { ThemeContext } from "../../../ThemeProvider/ThemeProvider";
import { clientWidth } from "../../../../utils";

/*
 * This component is a scrollable container that wraps around the 'MessageList' component.
 * The MessageList will ultimately grow taller than its container as it continues to receive
 * new messages, and users will need to have the ability to scroll up and down the chat window.
 * A "new message" button will be displayed when the user receives a new message, and is not scrolled
 * to the bottom. This button will be hidden if the user clicks on it, or manually scrolls
 * to the bottom. Otherwise, this component will auto-scroll to the bottom when a new message is
 * received, and the user is already scrolled to the bottom.
 *
 * Note that this component is tested with Cypress only.
 */
export class MessageListScrollContainer extends React.Component {
  chatThreadRef = React.createRef();
  innerScrollContainerRef = React.createRef();
  state = {
    isScrolledToBottom: true,
    showButton: false,
    messageNotificationCount: 0,
    scrollLockEnabled: false,
  };

  static contextType = ThemeContext;

  scrollToBottom() {
    const innerScrollContainerEl = this.innerScrollContainerRef.current; //this.chatThreadRef.current;
    innerScrollContainerEl.scrollTop = innerScrollContainerEl.scrollHeight;
  }

  componentDidMount() {
    if (clientWidth() < this.context.theme.responsive.breakpoints.md) {
      disableBodyScroll(this.innerScrollContainerRef.current);
      window.scrollTo(0, 0);
    }
    this.scrollToBottom();
    //this.chatThreadRef.current!.addEventListener('scroll', this.handleScroll);
  }

  // This component updates as users send new messages:
  componentDidUpdate(prevProps, prevState) {
    const hasNewMessages =
      this.props.messages.length !== prevProps.messages.length;

    if (prevState.isScrolledToBottom && hasNewMessages) {
      this.scrollToBottom();
    } else if (hasNewMessages) {
      const numberOfNewMessages =
        this.props.messages.length - prevProps.messages.length;

      this.setState((previousState) => ({
        // If there's at least one new message, show the 'new message' button:
        showButton: !previousState.isScrolledToBottom,
        // If 'new message' button is visible,
        // messageNotificationCount will be the number of previously unread messages + the number of new messages.
        // Otherwise, messageNotificationCount is set to 1:
        messageNotificationCount: previousState.showButton
          ? previousState.messageNotificationCount + numberOfNewMessages
          : 1,
      }));
    }

    //useEffect(() => {
    if (!this.chatThreadRef.current) {
      return;
    }
    if (
      this.chatThreadRef.current.scrollTop != 0 &&
      this.chatThreadRef.current.scrollHeight -
        this.chatThreadRef.current.scrollTop >
        1115
    ) {
      return; //  We aren't scrolled to the bottom
    }
    setTimeout(() => {
      if (this.chatThreadRef.current) {
        this.chatThreadRef.current.scrollTop =
          this.chatThreadRef.current?.scrollHeight;
      }
    }, 10); //  Some weird timing issue
    //}, [messages]);
  }

  handleScroll = throttle(() => {
    const innerScrollContainerEl = this.chatThreadRef.current;
    // Because this.handleScroll() is a throttled method,
    // it's possible that it can be called after this component unmounts, and this element will be null.
    // Therefore, if it doesn't exist, don't do anything:
    if (!innerScrollContainerEl) return;

    // On systems using display scaling, scrollTop may return a decimal value, so we need to account for this in the
    // "isScrolledToBottom" calculation.
    const isScrolledToBottom =
      Math.abs(
        innerScrollContainerEl.clientHeight +
          innerScrollContainerEl.scrollTop -
          innerScrollContainerEl.scrollHeight
      ) < 1;

    this.setState((prevState) => ({
      isScrolledToBottom,
      showButton: isScrolledToBottom ? false : prevState.showButton,
    }));
  }, 300);

  handleClick = () => {
    const innerScrollContainerEl = this.chatThreadRef.current;

    innerScrollContainerEl.scrollTo({
      top: innerScrollContainerEl.scrollHeight,
      behavior: "smooth",
    });

    this.setState({ showButton: false });
  };

  componentWillUnmount() {
    const innerScrollContainerEl = this.chatThreadRef.current;

    innerScrollContainerEl.removeEventListener("scroll", this.handleScroll);

    if (clientWidth() < this.context.theme.responsive.breakpoints.md) {
      enableBodyScroll(this.innerScrollContainerRef.current);
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div
        // className={classes.outerContainer}
        className="tbk-px-2_ lg:tbk-px-7_ tbk-h-full tbk-min-h-0 tbk-rounded-b-2xl tbk-bg-basic-white lg:tbk-h-124 lg:tbk-pb-2"
      >
        <div
          className="tbk-h-full tbk-overflow-y-auto"
          ref={this.innerScrollContainerRef}
          data-cy-message-list-inner-scroll
        >
          <div className="tbk-px-2_ lg:tbk-px-7">
            <div
              className="tbk-flex tbk-max-h-full tbk-flex-1 tbk-flex-col tbk-overflow-y-auto tbk-px-2 tbk-pb-2"
              ref={this.chatThreadRef}
            >
              {this.props.children}
              <Button
                className="tbk-hidden"
                onClick={this.handleClick}
                startIcon={<CaretDownIcon />}
                color="primary"
                variant="contained"
                data-cy-new-message-button
              >
                {this.state.messageNotificationCount} new message
                {this.state.messageNotificationCount > 1 && "s"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MessageListScrollContainer;
