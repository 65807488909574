import React from "react";

export default function PlayIcon({ size, className }) {
  return (
    <svg
      width={size || 25}
      height={size || 24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17.7778 10.212C18.7407 10.7845 18.7407 12.2155 17.7778 12.788L7.66667 18.7985C6.70371 19.371 5.5 18.6554 5.5 17.5106L5.5 5.48945C5.5 4.34458 6.7037 3.62903 7.66667 4.20147L17.7778 10.212Z"
        fill="currentColor"
      />
    </svg>
  );
}
