import React, { useEffect, useState } from "react";

// Components
import BottomNavLink from "../../BottomNavigation/BottomNavLink/BottomNavLink";

// Hooks
import useChatContext from "../../../hooks/useChatContext/useChatContext";

// Icons
import MessagesIcon from "../../../icons/MessagesIcon";

export default function ToggleChatButton() {
  const {
    setActiveChannel,
    isChatWindowOpen,
    setIsChatWindowOpen,
    conversation,
    unreadMessages,
  } = useChatContext();

  const toggleChatWindow = () => {
    setActiveChannel(null);
  };

  return (
    <BottomNavLink
      // data-cy-chat-button
      to="/account/messages"
      linkIcon={
        <div>
          <MessagesIcon />
          <div
            className={`tbk-absolute tbk--top-[3px] tbk-left-1.5 tbk-h-[10px] tbk-w-[10px] tbk-rounded-full tbk-bg-coral tbk-transition ${
              unreadMessages && unreadMessages.length > 0
                ? "tbk-opacity-1"
                : "tbk-opacity-0"
            }`}
          />
        </div>
      }
      text="Messages"
      onClick={toggleChatWindow}
    />
  );
}
