import React, { useEffect, useRef } from "react";

const TextAreaAutoSize = ({
  value,
  onChange,
  onKeyPress,
  placeholder,
  minRows = 1,
  maxRows = 3,
  className,
  ...props
}) => {
  const textAreaRef = useRef(null);

  const resize = () => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.style.height = "auto"; // Reset height
      textArea.style.height =
        textArea.value && textArea.value.includes("\n")
          ? `${textArea.scrollHeight}px`
          : "40px";
    }
  };

  useEffect(() => {
    const textArea = textAreaRef.current;

    if (textArea) {
      // Initial resize
      resize();

      // Event listeners for resizing
      const handleChange = () => resize();
      const handleCut = () => setTimeout(resize, 0);
      const handlePaste = () => setTimeout(resize, 0);
      const handleDrop = () => setTimeout(resize, 0);
      const handleKeyDown = () => setTimeout(resize, 0);

      textArea.addEventListener("change", handleChange);
      textArea.addEventListener("cut", handleCut);
      textArea.addEventListener("paste", handlePaste);
      textArea.addEventListener("drop", handleDrop);
      textArea.addEventListener("keydown", handleKeyDown);

      // Cleanup event listeners on unmount
      return () => {
        textArea.removeEventListener("change", handleChange);
        textArea.removeEventListener("cut", handleCut);
        textArea.removeEventListener("paste", handlePaste);
        textArea.removeEventListener("drop", handleDrop);
        textArea.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, []);

  return (
    <textarea
      ref={textAreaRef}
      className={`tbk-pb-1_ tbk-h-5_ tbk-pt-0.5_ tbk-text-main tbk-mr-[10px] tbk-w-full tbk-resize-none tbk-overflow-hidden tbk-rounded-2xl tbk-border tbk-border-blue-grey tbk-py-1 tbk-px-2 !tbk-leading-snug tbk-text-primary ${className}`}
      aria-label="chat input"
      placeholder={placeholder}
      onKeyPress={onKeyPress}
      onChange={onChange}
      value={value}
      style={{
        height: "40px",
        maxHeight: `${maxRows * 1.5}em`,
      }} // Adjust height based on rows
      {...props}
    />
  );
};

export default TextAreaAutoSize;
