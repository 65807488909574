import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Message, Chat, Channel, User, Membership } from "@pubnub/chat";
import { useAppState } from "../state";

// include the PubNub Chat SDK package in your code before initializing the client. Underneath, that also installs the PubNub JavaScript SDK "pubnub" package as a dependency.

const ChatEventTypes = {
  LEAVE: 0, //  Notify other members of a group that you are leaving that group
  JOINED: 3, //  Notify others in a group that you have joined as a new member (for public channels)
};

export const ChatContext = createContext(null);

export const ChatProvider = ({ children }) => {
  const { user } = useAppState();

  const isChatWindowOpenRef = useRef(false);
  //const [userId, setUserId] = useState<String | null | undefined>(user?.id);
  const [chatClient, setChat] = useState(); //useState<Client>();
  const [currentUser, setCurrentUser] = useState(null);
  const [loadMessage, setLoadMessage] = useState("Chat is initializing...");
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [conversation, setConversation] = useState(null);
  const [channels, setChannels] = useState([]);
  const [activeChannel, setActiveChannel] = useState(null);
  const [activeChannelPinnedMessage, setActiveChannelPinnedMessage] =
    useState(null);
  const [messages, setMessages] = useState([]);

  const [name, setName] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [typingData, setTypingData] = useState([]);
  const [refreshMembersTimeoutId, setRefreshMembersTimeoutId] = useState();
  const [initOnce, setInitOnce] = useState(0);

  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

  const [directChats, setDirectChats] = useState();
  const [publicChannelsMemberships, setPublicChannelsMemberships] = useState();
  const [privateGroupsMemberships, setPrivateGroupsMemberships] = useState();
  const [directChatsMemberships, setDirectChatsMemberships] = useState();
  const [privateGroupsUsers, setPrivateGroupsUsers] = useState([]);
  const [privateGroups, setPrivateGroups] = useState();
  const [directChatsUsers, setDirectChatsUsers] = useState([]);
  const [publicChannels, setPublicChannels] = useState();
  const [publicChannelsUsers, setPublicChannelsUsers] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [latestMessages, setLatestMessages] = useState([]);

  /* Bootstrap the application if it is run in an empty keyset */
  async function keysetInit(chat) {
    if (!chat) return;
    try {
      await chat?.createPublicConversation({
        channelId: "public-general",
        channelData: {
          name: "General Chat",
          description: "Public group for general conversation",
          custom: {
            profileUrl: "/group/globe1.svg",
          },
        },
      });
    } catch (e) {}

    try {
      await chat?.createPublicConversation({
        channelId: "public-work",
        channelData: {
          name: "Work Chat",
          description: "Public group for conversation about work",
          custom: {
            profileUrl: "/group/globe2.svg",
          },
        },
      });
    } catch (e) {}
  }

  /*  Initialize or Update all the state arrays related to public groups */
  async function updateChannelMembershipsForPublic(chat) {
    if (!chat) {
      return;
    }
    //  During development there was an issue filtering on getMemberships on the server, which has since been fixed, so this code could be made more efficient
    chat.currentUser
      .getMemberships({ filter: "channel.type == 'direct'" })
      .then(async (membershipResponse) => {
        const currentMemberOfThesePublicChannels =
          membershipResponse.memberships.map((m) => m.channel);

        setPublicChannels(currentMemberOfThesePublicChannels);
        const publicChannelMemberships = membershipResponse.memberships;
        setPublicChannelsMemberships(publicChannelMemberships);

        //  Get the users for every public group I am a member of
        let tempPublicUsers = [];
        for (
          var indexGroup = 0;
          indexGroup < currentMemberOfThesePublicChannels.length;
          indexGroup++
        ) {
          var tempIndex = indexGroup;
          const response = await currentMemberOfThesePublicChannels[
            indexGroup
          ].getMembers({
            sort: { updated: "desc" },
            limit: 40,
          });
          if (response.members) {
            //  response contains the most recent 40 members
            const channelUsers = response.members.map((membership, index) => {
              return membership.user;
            });
            tempPublicUsers[tempIndex] = channelUsers;
          }
        }
        setPublicChannelsUsers(tempPublicUsers);
      });
  }

  /* Initialize or Update all the state arrays related to private groups */
  async function updateChannelMembershipsForGroups(
    chat,
    desiredChannelId = ""
  ) {
    if (!chat) return;
    chat.currentUser
      .getMemberships({
        filter: "channel.type == 'group'",
        sort: { updated: "desc" },
      })
      .then(async (membershipResponse) => {
        const currentMemberOfTheseGroupChannels =
          membershipResponse.memberships.map((m) => m.channel);
        //  Look for the desired channel ID
        for (var i = 0; i < currentMemberOfTheseGroupChannels.length; i++) {
          if (currentMemberOfTheseGroupChannels[i].id === desiredChannelId) {
            //  We have found the channel we want to focus
            setActiveChannel(currentMemberOfTheseGroupChannels[i]);
          }
        }

        setPrivateGroups(currentMemberOfTheseGroupChannels);
        const groupChannelMemberships = membershipResponse.memberships;
        setPrivateGroupsMemberships(groupChannelMemberships);

        //  Get the users for every private group I am a member of
        let tempGroupUsers = [];
        for (
          var indexGroup = 0;
          indexGroup < currentMemberOfTheseGroupChannels.length;
          indexGroup++
        ) {
          //currentMemberOfTheseGroupChannels.forEach((channel, index) => {
          var tempIndex = indexGroup;
          const response = await currentMemberOfTheseGroupChannels[
            indexGroup
          ].getMembers({
            sort: { updated: "desc" },
            limit: 100,
          });
          if (response.members) {
            const channelUsers = response.members.map((membership, index) => {
              return membership.user;
            });
            tempGroupUsers[tempIndex] = channelUsers;
          }
        }
        setPrivateGroupsUsers(tempGroupUsers);
      });
  }

  /* Initialize or Update all the state arrays related to Direct message pairs */
  async function updateChannelMembershipsForDirects(
    chat,
    desiredChannelId = ""
  ) {
    if (!chat) return;
    chat.currentUser
      .getMemberships({
        filter: "channel.type == 'direct'",
        sort: { updated: "desc" },
      })
      .then(async (membershipResponse) => {
        const currentMemberOfTheseDirectChannels =
          membershipResponse.memberships.map((m) => m.channel);
        //  Look for the desired channel ID
        for (var i = 0; i < currentMemberOfTheseDirectChannels.length; i++) {
          if (currentMemberOfTheseDirectChannels[i].id === desiredChannelId) {
            //  We have found the channel we want to focus
            setActiveChannel(currentMemberOfTheseDirectChannels[i]);
          }
        }
        setDirectChats(currentMemberOfTheseDirectChannels);
        const directChannelMemberships = membershipResponse.memberships;
        setDirectChatsMemberships(directChannelMemberships);

        //  Get the users for every direct message pair I am a member of
        let tempDirectUsers = [];
        for (
          var indexDirects = 0;
          indexDirects < currentMemberOfTheseDirectChannels.length;
          indexDirects++
        ) {
          var tempIndex = indexDirects;
          const response = await currentMemberOfTheseDirectChannels[
            indexDirects
          ].getMembers({
            sort: { updated: "desc" },
            limit: 100,
          });

          if (response.members) {
            //  response contains the most recent 100 members
            const channelUsers = response.members.map((membership, index) => {
              return membership.user;
            });
            tempDirectUsers[tempIndex] = channelUsers;
          }
        }
        setDirectChatsUsers(tempDirectUsers);
      });
  }

  useEffect(() => {
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen) {
      setHasUnreadMessages(false);
    }
  }, [isChatWindowOpen]);

  const actionCompleted = (a) => {};

  function updateUnreadMessagesCounts() {
    chatClient?.getUnreadMessagesCounts({}).then((result) => {
      let unreadMessagesOnChannel = [];
      result.forEach((element, index) => {
        let newUnreadMessage = {
          channel: element.channel,
          count: element.count,
        };
        unreadMessagesOnChannel.push(newUnreadMessage);
      });
      setUnreadMessages(unreadMessagesOnChannel);
    });
  }

  async function fetchLatestMessages() {
    // if (!chatClient || !publicChannels) {
    //   return;
    // }

    if (!chatClient || !directChats || !Array.isArray(directChats)) {
      return;
    }

    // const latestMessages = await Promise.all(
    //   directChats?.map(async (channel) => {
    //     const messagesResponse = await channel?.getMessages({ limit: 1 });
    //     const res = {
    //       channelId: channel?.id,
    //       latestMessage: messagesResponse && messagesResponse.length ? messagesResponse.messages[0] : null,
    //     } as LatestMessagesOnChannel;

    //     return res;
    //   })
    // );

    const latestMessages = await Promise.all(
      directChats?.map(async (channel) => {
        const historicalMessagesObj = await channel?.getHistory({ count: 1 });

        const res = {
          channelId: channel.id,
          message:
            historicalMessagesObj && historicalMessagesObj.messages.length
              ? historicalMessagesObj.messages[0]
              : null,
        };

        // //  Run through the historical messages and set the most recently received one (that we were not the sender of) as read
        // if (historicalMessagesObj.messages) {
        //   if (historicalMessagesObj.messages.length == 0) {
        //     setLoadingMessage('No messages in this chat yet')
        //   } else {
        //     setMessages(messages => {
        //       return uniqueById([...historicalMessagesObj.messages]) //  Avoid race condition where message was being added twice
        //     })
        //     for (
        //       var i = historicalMessagesObj.messages.length - 1;
        //       i >= 0;
        //       i--
        //     ) {
        //       await localCurrentMembership?.setLastReadMessageTimetoken(
        //         historicalMessagesObj.messages[i].timetoken
        //       )
        //       updateUnreadMessagesCounts()
        //       break
        //     }
        //   }
        // }
        // const messagesResponse = await channel?.getMessage({ limit: 1 });
        // const res: LatestMessagesOnChannel = {
        //   channelId: channel.id,
        //   latestMessage: messagesResponse && messagesResponse.messages.length ? messagesResponse.messages[0] : null,
        // };

        return res;
      })
    );

    // Update the state with the latest messages
    setLatestMessages(latestMessages);
  }

  /* Initialization logic */
  useEffect(() => {
    async function init() {
      // const searchParams = new URLSearchParams(window.location.search);

      // setUserId(searchParams.get('userId'));
      if (user?.id == null || user.id === "") {
        setLoadMessage("Retrieving User ID");
        return;
      }
      if (!process.env.REACT_APP_PUBNUB_PUBLISH_KEY) {
        setLoadMessage("No Publish Key Found");
        return;
      }
      if (!process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY) {
        setLoadMessage("No Subscribe Key Found");
        return;
      }
      const localChat = await Chat.init({
        publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
        subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
        userId: `customer_${user.id}`,
        typingTimeout: 5000,
        storeUserActivityTimestamps: true,
        storeUserActivityInterval: 300000 /* 5 minutes */,
        //authKey: accessManagerToken,
      });
      setChat(localChat);
      setCurrentUser(localChat.currentUser);

      if (localChat && !localChat.currentUser.profileUrl) {
        await localChat.currentUser.update({
          name: `${user.firstName} ${user.lastName}`, //'' + user.id,
          profileUrl: user.photoWithBackgroundUrl, //testData.avatars[randomProfileUrl]
        });

        await localChat.currentUser.update({
          name: `${user.firstName} ${user.lastName}`,
        });

        setName(`${user.firstName} ${user.lastName}`); //('' + user.id);

        setProfileUrl(
          user.photoWithBackgroundUrl /*testData.avatars[randomProfileUrl]*/
        );
      } else {
        if (localChat && localChat.currentUser.name) {
          setName(localChat.currentUser.name);
        }

        setProfileUrl(localChat && localChat.currentUser.profileUrl);
      }

      await localChat
        .getChannels({ filter: `type == 'direct'` })
        .then(async (channelsResponse) => {
          if (channelsResponse.channels.length < 2) {
            //  There are fewer than the expected number of public channels on this keyset, do any required Keyset initialization
            await keysetInit(localChat);
            //location.reload()
          } else {
            //  Join public channels
            if (channelsResponse.channels.length > 0) {
              setLoadMessage("Creating Memberships");
              //  Join each of the public channels
              const currentMemberships =
                await localChat.currentUser.getMemberships({
                  filter: "channel.type == 'direct'",
                });

              //  Test to see if we are already a member of the public channels, and if not, join them.
              const publicMembership =
                await currentMemberships.memberships.find(
                  (membership) => membership.channel.id == "public-general"
                );
              const workMembership = await currentMemberships.memberships.find(
                (membership) => membership.channel.id == "public-work"
              );
              if (!publicMembership) {
                const publicChannel = await localChat.getChannel(
                  "public-general"
                );
                publicChannel?.join((message) => {
                  //  We have a message listener elsewhere for consistency with private and direct chats
                });
              }
              if (!workMembership) {
                const workChannel = await localChat.getChannel("public-work");
                workChannel?.join((message) => {
                  //  We have a message listener elsewhere for consistency with private and direct chats
                });
              }
            }
          }
        });

      //  Initialization for private groups and direct messages
      //  Calling inside a timeout as there was some timing issue when creating a new user
      let setTimeoutIdInit = setTimeout(() => {
        updateChannelMembershipsForPublic(localChat);
        updateChannelMembershipsForDirects(localChat);
        updateChannelMembershipsForGroups(localChat);
      }, 500);

      actionCompleted({
        action: "Login",
        blockDuplicateCalls: false,
        debug: false,
      });
    }
    if (chatClient) {
      return;
    }
    init();
  }, [user /*, chatClient*/]);

  useEffect(() => {
    //  Connect to the direct chats whenever they change so we can keep a track of unread messages
    //  Called once everything is initialized
    if (!chatClient) return;
    if (!publicChannels) return;
    if (!directChats) return;
    if (!privateGroups) return;
    // if (!activeChannel) return;

    function updateUnreadMessagesCounts() {
      chatClient?.getUnreadMessagesCounts({}).then((result) => {
        let unreadMessagesOnChannel: UnreadMessagesOnChannel[] = [];
        result.forEach((element, index) => {
          let newUnreadMessage: UnreadMessagesOnChannel = {
            channel: element.channel,
            count: element.count,
          };
          unreadMessagesOnChannel.push(newUnreadMessage);
        });
        setUnreadMessages(unreadMessagesOnChannel);
      });
    }

    var publicHandlers = [];
    publicChannels.forEach((channel, index) => {
      const disconnectHandler = channel.connect((message) => {
        if (
          !(
            message.userId == chatClient.currentUser.id ||
            message.channelId == activeChannel.id
          )
        ) {
          updateUnreadMessagesCounts();
        }
      });
      publicHandlers.push(disconnectHandler);
    });
    var directHandlers = [];
    directChats.forEach((channel, index) => {
      const disconnectHandler = channel.connect((message) => {
        if (
          !(
            message.userId == chatClient.currentUser.id ||
            message.channelId == activeChannel.id
          )
        ) {
          updateUnreadMessagesCounts();
        }
        fetchLatestMessages();
      });
      directHandlers.push(disconnectHandler);
    });
    var privateHandlers = [];
    privateGroups.forEach((channel, index) => {
      const disconnectHandler = channel.connect((message) => {
        if (
          !(
            message.userId == chatClient.currentUser.id ||
            message.channelId == activeChannel.id
          )
        ) {
          updateUnreadMessagesCounts();
        }
      });
      privateHandlers.push(disconnectHandler);
    });

    updateUnreadMessagesCounts(); //  Update the unread message counts whenever the channel changes

    fetchLatestMessages();

    return () => {
      publicHandlers.forEach((handler) => {
        handler();
      });
      directHandlers.forEach((handler) => {
        handler();
      });
      privateHandlers.forEach((handler) => {
        handler();
      });
    };
  }, [chatClient, publicChannels, directChats, activeChannel, privateGroups]);

  //  Invoked whenever the active channel changes
  useEffect(() => {
    if (!chatClient) return;
    if (!activeChannel) return;

    //  Set the pinned message for the active channel, this returns an updated channel ID so retrieve based on the server-channel
    chatClient.getChannel(activeChannel.id).then((localActiveChannel) => {
      localActiveChannel
        ?.getPinnedMessage()
        .then((localActiveChannelPinnedMessage) => {
          setActiveChannelPinnedMessage(localActiveChannelPinnedMessage);
        });
    });

    //  Only register typing indicators for non-public channels
    if (activeChannel.type == "public") {
      return;
    }
    return activeChannel.getTyping((value) => {
      const findMe = value.indexOf(chatClient.currentUser.id);
      if (findMe > -1) {
        value.splice(findMe, 1);
      }
      setTypingData(value);
    });
  }, [chatClient, activeChannel]);

  useEffect(() => {
    //  This use effect is only called once after the local user cache has been initialized
    if (chatClient && publicChannelsUsers?.length > 0 && initOnce == 0) {
      setInitOnce(1);
      if (publicChannels) {
        // setActiveChannel(publicChannels[0]);
        // sendChatEvent(ChatEventTypes.JOINED, publicChannelsUsers[0], {
        //   userId: chatClient.currentUser.id,
        // });
        updateUnreadMessagesCounts(); //  Update the unread message counts whenever the channel changes
      } else {
        console.log("Error: Public Channels was undefined at launch");
      }
    }
  }, [chatClient, publicChannelsUsers, initOnce]);

  useEffect(() => {
    //  Get updates on the current user's name and profile URL
    if (!currentUser) {
      return;
    }
    return currentUser.streamUpdates((updatedUser) => {
      if (updatedUser.name) {
        setName(updatedUser.name);
      }
      if (updatedUser.profileUrl) {
        setProfileUrl(updatedUser.profileUrl);
      }
    });
  }, [currentUser]);

  /* Handle updates to the Public Channels */
  useEffect(() => {
    if (chatClient && publicChannels && publicChannels.length > 0) {
      return Channel.streamUpdatesOn(publicChannels, (channels) => {
        const updatedPublicChannels = publicChannels.map(
          (publicChannel, index) => {
            if (channels[index].name) {
              publicChannel.name = channels[index].name;
            }
            if (channels[index].custom?.profileUrl) {
              publicChannel.custom.profileUrl =
                channels[index].custom.profileUrl;
            }
            return publicChannel;
          }
        );
        setPublicChannels(updatedPublicChannels);
      });
    }
  }, [chatClient, publicChannels]);

  /* Handle updates to the Private Groups */
  useEffect(() => {
    if (chatClient && privateGroups && privateGroups.length > 0) {
      return Channel.streamUpdatesOn(privateGroups, (channels) => {
        const updatedPrivateGroups = privateGroups.map(
          (privateGroup, index) => {
            if (channels[index].name) {
              privateGroup.name = channels[index].name;
            }
            return privateGroup;
          }
        );
        setPrivateGroups(updatedPrivateGroups);
      });
    }
  }, [chatClient, privateGroups]);

  /* Handle updates to the Direct Message Groups */
  useEffect(() => {
    if (chatClient && directChats && directChats.length > 0) {
      //  Note: We do not need to stream updates on direct chats since we do not use the channel name, only the user info (name, avatar)
    }
  }, [chatClient, directChats]);

  /* Listen for events using the Chat event mechanism*/
  useEffect(() => {
    if (!chatClient) {
      return;
    }
    const removeCustomListener = chatClient.listenForEvents({
      channel: chatClient.currentUser.id,
      type: "custom",
      method: "publish",
      callback: async (evt) => {
        switch (evt.payload.action) {
          case ChatEventTypes.LEAVE:
            //  Someone is telling us they are leaving a group
            if (evt.payload.body.isDirectChat) {
              //  Our partner left the direct chat, leave it ourselves
              const channel = await chatClient.getChannel(
                evt.payload.body.channelId
              );
              await channel?.leave();
              if (activeChannel?.id === evt.payload.body.channelId) {
                if (publicChannels) {
                  setActiveChannel(publicChannels[0]);
                }
              }
            }
            refreshMembersFromServer();
            break;
          case ChatEventTypes.JOINED:
            //  Someone has joined one of the public channels
            refreshMembersFromServer();
            break;
        }
      },
    });

    const removeModerationListener = chatClient.listenForEvents({
      channel: chatClient.currentUser.id,
      type: "moderation",
      callback: async (evt) => {
        // let moderationMessage = ''
        // let notificationSeverity: ToastType = ToastType.INFO
        // if (evt.payload.restriction == 'muted') {
        //   moderationMessage = `You have been MUTED by the administrator`
        //   notificationSeverity = ToastType.ERROR
        // } else if (evt.payload.restriction == 'banned') {
        //   moderationMessage = `You have been BANNED by the administrator for the following reason: ${evt.payload.reason}`
        //   notificationSeverity = ToastType.ERROR
        // } else if (evt.payload.restriction == 'lifted') {
        //   moderationMessage = `Your previous restrictions have been LIFTED by the administrator`
        //   notificationSeverity = ToastType.CHECK
        // }
        // showUserMessage(
        //   'Moderation Event:',
        //   moderationMessage,
        //   'https://www.pubnub.com/how-to/monitor-and-moderate-conversations-with-bizops-workspace/',
        //   notificationSeverity
        // );
      },
    });

    const removeMentionsListener = chatClient.listenForEvents({
      user: chatClient.currentUser.id,
      type: "mention",
      callback: async (evt) => {
        const channelId = evt.payload.channel;
        const messageTimetoken = evt.payload.messageTimetoken;
        const channel = await chatClient.getChannel(channelId);
        const message = await channel?.getMessage(messageTimetoken);
        // showUserMessage(
        //   'You Were Mentioned:',
        //   'You have been mentioned in the following message: ' +
        //     message?.content.text,
        //   'https://www.pubnub.com/docs/chat/chat-sdk/build/features/custom-events#events-for-mentions',
        //   0//ToastType.INFO
        // );
      },
    });

    const removeInvite = chatClient.listenForEvents({
      channel: chatClient.currentUser.id,
      type: "invite",
      callback: async (evt) => {
        //  Somebody has added us to a new group chat or DM
        refreshMembersFromServer();
      },
    });

    return () => {
      removeCustomListener();
      removeModerationListener();
      removeMentionsListener();
      removeInvite();
    };
  }, [chatClient]);

  /*
  Will refresh all of the users and channels associated with this user's memberships
  You could do this using the objects from the StreamUpdatesOn() callbacks, but
  this way is expedient for a proof of concept.  The Channel name updates use the StreamUpdatesOn()
  callback directly.
  */
  const refreshMembersFromServer = useCallback(
    async (
      forceUpdateDirectChannels = false,
      forceUpdateGroupChannels = false,
      desiredChannelId = ""
    ) => {
      if (!chatClient) {
        return;
      }
      //return //  TODO REMOVE THIS TO ENABLE OBJECT UPDATES.  IT'S JUST A PAIN WHEN DEBUGGING

      clearTimeout(refreshMembersTimeoutId);

      if (forceUpdateDirectChannels) {
        //updateChannelMembershipsForPublic(chat)  //  Not needed as we only call this when we create a new group or DM
        updateChannelMembershipsForDirects(chatClient, desiredChannelId);
      } else if (forceUpdateGroupChannels) {
        updateChannelMembershipsForGroups(chatClient, desiredChannelId);
      } else {
        let setTimeoutId = setTimeout(() => {
          updateChannelMembershipsForPublic(chatClient);
          updateChannelMembershipsForDirects(chatClient);
          updateChannelMembershipsForGroups(chatClient);
        }, 3000);
        setRefreshMembersTimeoutId(setTimeoutId);
      }

      return;
    },
    [chatClient, refreshMembersTimeoutId]
  );

  function sendChatEvent(eventType, recipients, payload) {
    recipients.forEach(async (recipient) => {
      //  Don't send the message to myself
      if (recipient.id !== chatClient?.currentUser.id) {
        await chatClient?.emitEvent({
          channel: recipient.id,
          type: "custom",
          method: "publish",
          payload: {
            action: eventType,
            body: payload,
          },
        });
      }
    });
  }

  return (
    <ChatContext.Provider
      value={{
        isChatWindowOpen,
        setIsChatWindowOpen,
        //connect,
        chatClient,
        currentUser,
        hasUnreadMessages,
        messages,
        conversation,
        // channel:
        activeChannel,
        setActiveChannel,
        activeChannelPinnedMessage,
        setActiveChannelPinnedMessage,
        setUnreadMessages,
        privateGroups,
        privateGroupsUsers,
        directChats,
        directChatsUsers,
        publicChannels,
        publicChannelsUsers,
        unreadMessages,
        latestMessages,
        publicChannelsMemberships,
        privateGroupsMemberships,
        directChatsMemberships,
        updateChannelMembershipsForPublic,
        updateChannelMembershipsForDirects,
        updateChannelMembershipsForGroups,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
