import React, { useCallback } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import linkify from "linkify-it";

import readIcon from "./read.svg";
import sentIcon from "./sent.svg";

function addLinks(text) {
  const matches = linkify().match(text);
  if (!matches) return text;

  const results = [];
  let lastIndex = 0;

  matches.forEach((match, i) => {
    results.push(text.slice(lastIndex, match.index));
    results.push(
      <Link target="_blank" rel="noreferrer" href={match.url} key={i}>
        {match.text}
      </Link>
    );
    lastIndex = match.lastIndex;
  });

  results.push(text.slice(lastIndex, text.length));

  return results;
}

export default function TextMessage({
  body,
  received,
  dateCreated,
  readReceipts,
  showReadIndicator = true,
  isLocalParticipant,
  currentUserId,
  message,
  senderName,
}) {
  //  Originally I was not writing the 'lastTimetoken' for messages I was sending myself, however
  //  that caused the Chat SDK's notion of an unread message count inconsistent, so I am removing
  //  readReceipts I set myself in this useCallback
  const determineReadStatus = useCallback((timetoken, readReceipts) => {
    if (!readReceipts) {
      return false;
    }
    let returnVal = false;
    for (var i = 0; i < Object.keys(readReceipts).length; i++) {
      const receipt = Object.keys(readReceipts)[i];
      const findMe = readReceipts[receipt].indexOf(currentUserId);
      if (findMe > -1) {
        readReceipts[receipt].splice(findMe, 1);
      }
      if (readReceipts[receipt].length > 0 && receipt >= timetoken) {
        return true;
      }
    }
    return false;
  }, []);

  return (
    <div
      className={`tbk-hyphens-auto tbk-relative tbk-mt-1.5 tbk-inline-flex tbk-items-center tbk-whitespace-pre-wrap tbk-break-words tbk-rounded-lg tbk-px-1.5 tbk-pb-1 tbk-pt-1 tbk-shadow-trube ${
        isLocalParticipant
          ? "tbk-ml-auto tbk-max-w-[85%] tbk-bg-blue-grey tbk-text-basic-white"
          : "tbk-mr-4"
      }`}
    >
      {!received && showReadIndicator && (
        <img
          src={
            determineReadStatus(message?.timetoken, readReceipts)
              ? readIcon
              : sentIcon
          }
          alt="Read"
          className={`tbk-absolute tbk-right-0 tbk-top-0`}
        />
      )}
      <div className="tbk-ml-4_ tbk-grow">
        {!isLocalParticipant ? (
          <div className="tbk-text-main tbk-text-coral">{senderName}</div>
        ) : null}
        <div className="tbk-text-secondary">{addLinks(body)}</div>
        <div className="tbk-text-right">
          <div className="tbk-blue-grey-light tbk-text-extra-small tbk-bottom-0 tbk-right-0 tbk-inline-block">
            {dateCreated}
          </div>
        </div>
      </div>
    </div>
  );
}
