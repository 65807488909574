import React, { useState } from "react";
import { Channel, Message as pnMessage, Membership, User } from "@pubnub/chat";
import ChatMenuItem from "./ChatMenuItem/ChatMenuItem";
import ChatMenuHeader from "./ChatMenuHeader/ChatMenuHeader";
import { LatestMessagesOnChannel } from "../../context/ChatProvider";
import avatarPlaceholder from "../../images/generic-trainerphoto.svg"; //.png

export default function ChatSelectionMenu({
  chatSelectionMenuMinimized,
  setChatSelectionMenuMinimized,
  chat,
  setCreatingNewMessage,
  setShowThread,
  unreadMessages,
  latestMessages,
  publicChannels,
  publicChannelsMemberships,
  privateGroups,
  privateGroupsUsers,
  privateGroupsMemberships,
  directChats,
  directChatsUsers,
  directChatsMemberships,
  activeChannel,
  setActiveChannel,
  setActiveChannelPinnedMessage,
  updateUnreadMessagesCounts,
  currentUserProfileUrl,
  showUserMessage,
}: ChatSelectionMenuProps) {
  const [unreadExpanded, setUnreadExpanded] = useState(true);
  const [publicExpanded, setPublicExpanded] = useState(true);
  const [groupsExpanded, setGroupsExpanded] = useState(true);
  const [directMessagesExpanded, setDirectMessagesExpanded] = useState(true);
  const isLg = false;
  const [searchChannels, setSearchChannels] = useState("");

  function handleChatSearch(term: string) {
    setSearchChannels(term);
  }

  return (
    <div
      id="chats-menu"
      className={`tbk-flex tbk-h-144 tbk-grow tbk-flex-col tbk-bg-basic-white tbk-pt-4 tbk-pb-2 ${
        !isLg && chatSelectionMenuMinimized ? "tbl-w-5 tbk-min-w-5" : ""
      } tbk-select-none tbk-overflow-y-auto tbk-overscroll-none tbk-rounded-2xl tbk-py-0`}
    >
      <div
        className={`flex flex-col tbk-px-2 lg:tbk-px-7 ${
          !isLg && chatSelectionMenuMinimized ? "hidden" : "flex"
        }`}
      >
        {false && (
          <div className={`relative px-4 mt-5`}>
            <input
              id="chats-search-input"
              value={searchChannels}
              className="w-full pl-9 rounded-md bg-navy50 border  border-neutral-400 py-[9px] flex px-[13px] text-sm focus:ring-1 focus:ring-inputring outline-none placeholder:text-neutral-500"
              placeholder="Search"
              onChange={(e) => {
                handleChatSearch(e.target.value);
              }}
            />
            {/*<Image
            src='/icons/search.svg'
            alt='Search Icon'
            className='absolute left-6 top-1/2 h-[20px] w-[20px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900'
            width={20}
            height={20}
            priority
          />*/}
          </div>
        )}

        {false && unreadMessages && unreadMessages.length > 0 && (
          <ChatMenuHeader
            text="UNREAD"
            expanded={unreadExpanded}
            expandCollapse={() => {
              setUnreadExpanded(!unreadExpanded);
            }}
            action={async () => {
              const markedAsRead = await chat.markAllMessagesAsRead();
              updateUnreadMessagesCounts();

              showUserMessage(
                "Success:",
                "All messsages have been marked as read, and sent receipts are updated accordingly",
                "https://www.pubnub.com/docs/chat/chat-sdk/build/features/messages/unread#mark-messages-as-read-all-channels",
                0 //ToastType.CHECK
              );
            }}
          />
        )}

        {/*<div className="w-full border border-navy200 mt-4"></div>
        <ChatMenuHeader
          text="DIRECT MESSAGES"
          expanded={directMessagesExpanded}
          expandCollapse={() => setDirectMessagesExpanded(!directMessagesExpanded)}
          //actionIcon={ChatHeaderActionIcon.ADD}
          action={setCreatingNewMessage}
        />*/}
        {directMessagesExpanded && (
          <div>
            {directChats ? (
              directChats.map(
                (directChat, index) =>
                  (
                    directChatsUsers[index]?.find(
                      (user) => user.id !== chat.currentUser.id
                    )?.name ?? ""
                  )
                    .toLowerCase()
                    .indexOf(searchChannels.toLowerCase()) > -1 && (
                    <ChatMenuItem
                      key={index}
                      avatarUrl={
                        directChatsUsers[index]?.find(
                          (user) => user.id !== chat.currentUser.id
                        )?.profileUrl
                          ? directChatsUsers[index]?.find(
                              (user) => user.id !== chat.currentUser.id
                            )?.profileUrl
                          : avatarPlaceholder
                      }
                      text={
                        directChatsUsers[index]?.find(
                          (user) => user.id !== chat.currentUser.id
                        )?.name
                      }
                      latestMessage={
                        ///*unreadMessage.channel.type === 'direct' && */directChats
                        latestMessages.find(
                          (msg: LatestMessagesOnChannel) =>
                            msg.channelId === directChat.id
                        )
                      }
                      // present={
                      //   directChatsUsers[index]?.find(
                      //     user => user.id !== chat.currentUser.id
                      //   )?.active
                      //     ? PresenceIcon.ONLINE
                      //     : PresenceIcon.OFFLINE
                      // }
                      setActiveChannel={() => {
                        setCreatingNewMessage(false);
                        setActiveChannelPinnedMessage(null);
                        setActiveChannel(directChats[index]);
                      }}
                    />
                  )
              )
            ) : (
              <h2
                variant="body1"
                className="tbk-text-h3-subtitle tbk-mt-6 tbk-mb-6 tbk-text-center tbk-text-blue-grey"
              >
                You have no messages yet.
              </h2>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
