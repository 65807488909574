/**
 * Messages Component in User Profile Page.
 *
 * @author Vladimir Shishlyannikov
 * @created 2024/12/23
 * @copyright 2024-present iWoo Ltd. All rights reserved.
 */

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Modal from "react-modal";
import { useAppState } from "../../../state";
import Transition, {
  ENTERED,
  ENTERING,
  EXITED,
  EXITING,
} from "react-transition-group/Transition";

// Components
import Button from "../../Button/Button";
import ScrollToTopOnMount from "../../ScrollToTopOnMount/ScrollToTopOnMount";
import RepeatBookingFlowPopup from "../../RepeatBookingFlowPopup/RepeatBookingFlowPopup";
import BookingFlowPanelHeader from "../../BookingFlow/BookingFlowPanelHeader/BookingFlowPanelHeader";
import RepeatBookingFlowPanel from "../../RepeatBookingFlowPanel/RepeatBookingFlowPanel";
import TruBeDrawer from "../../TruBeDrawer/TruBeDrawer";
import ChatWindow from "../../ChatWindow/ChatWindow";

// Hooks
import useTheme from "../../ThemeProvider/useTheme/useTheme";

// Icons
import SessionTypeIcon from "../SessionTypeIcon";
import SessionTypeIconGreyedOut from "../../../icons/SessionTypeIconGreyedOut";
import CloseIcon from "../../../icons/CloseIcon";
import CheckNiceIcon from "../../../icons/CheckNiceIcon";

// Utils
import { getMomentWithTz } from "../../../utils/date";
import { clientWidth, toQueryString } from "../../../utils";
import { modalStyles } from "../../../constants";

const transitionClasses = {
  [ENTERED]: "booking-flow-popup-transition-entered",
  [ENTERING]: "booking-flow-popup-transition-entering",
  [EXITING]: "booking-flow-popup-transition-exiting",
  [EXITED]: "booking-flow-popup-transition-exited",
};

const Messages = ({ bundles, onStartBookingClick }) => {
  const history = useHistory();
  const { theme } = useTheme();

  const {
    user,
    packages,
    loadBooking,
    // loadCustomerMessages,
    partnerAvailability,
    partnerAvailabilityLoaded,
    loadPartnerAvailability,
    clearPartnerAvailabilityLoaded,
    clearPartnerSummaryReceive,
    isFetching,
  } = useAppState();

  const [infoModalTitle, setInfoModalTitle] = useState(
    "Verification Email Has Been Sent"
  );
  const [infoModalMsg, setInfoModalMsg] = useState(
    `We sent you a link at ${
      user && user.email
    }. Please, open the link to verify your account`
  );
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const [nowTime] = useState(getMomentWithTz(new Date()));
  const [availabilityCalendarMin] = useState(
    getMomentWithTz(new Date()).startOf("day").add(330 /*05:30*/, "minute")
  );
  const [availabilityCalendarMax] = useState(
    getMomentWithTz(new Date())
      .startOf("day")
      .add(1410 /*23:30*/, "minute")
      .add(2, "month")
  );
  const [booking, setBooking] = useState(null);
  const [_directBookingTime, setDirectBookingTime] = useState(undefined);
  const [isRepeatBookingFlowPopupOpen, setIsRepeatBookingFlowPopupOpen] =
    useState(false);
  const [isRepeatBookingFlowPanelOpen, setIsRepeatBookingFlowPanelOpen] =
    useState(false);
  const [
    isRepeatBookingFlowPanelFullyOpen,
    setIsRepeatBookingFlowPanelFullyOpen,
  ] = useState(false);

  const infoModalResolveRef = useRef(null);
  const panelRef = useRef(null);
  const repeatBookingFlowPopupRef = useRef(null);
  const repeatBookingFlowPanelRef = useRef(null);

  useEffect(() => {
    // if (user) {
    //   loadCustomerMessages(user.id, {
    //     page: 0,
    //     size: 10,
    //     sort: ["status,asc", "id,desc"],
    //   });
    // }
    // clearPartnerSummaryReceive(); // To prevent showing previously loaded partner in Checkout
  }, [user]);

  const openInfoModal = useCallback((msg = infoModalMsg) => {
    return new Promise((resolve, reject) => {
      infoModalResolveRef.current = resolve;

      setIsInfoModalOpen(true);

      setInfoModalTitle("Verification Email has been Sent");
      setInfoModalMsg(msg);
    });
  }, []);

  const closeInfoModal = useCallback(() => {
    infoModalResolveRef.current();
    infoModalResolveRef.current = null;

    setIsInfoModalOpen(false);
  }, []);

  const handleDirectBookingTimeChange = useCallback(
    (dateTime, isTimeSelected) => {
      // Analytics.trackEvent(TRAINERS_LIST_PAGE_CATEGORY, '', EVENT_DATETIME_CHANGE);

      setDirectBookingTime(dateTime ? dateTime.getTime() : undefined);
    },
    []
  );

  const closeRepeatBookingFlowPopup = useCallback(() => {
    setIsRepeatBookingFlowPopupOpen(false);
    clearPartnerSummaryReceive(); // To prevent showing previously loaded partner in Checkout
    setBooking(null);
  }, []); // eslint-disable-line

  const closeRepeatBookingFlowPanel = useCallback(() => {
    setIsRepeatBookingFlowPanelOpen(false);
    setIsRepeatBookingFlowPanelFullyOpen(false);
    clearPartnerSummaryReceive(); // To prevent showing previously loaded partner in Checkout
    setBooking(null);
  }, []); // eslint-disable-line

  const handleBottomReached = useCallback(() => {
    if (isRepeatBookingFlowPanelOpen) {
      setIsRepeatBookingFlowPanelOpen(false);
    }
  }, [isRepeatBookingFlowPanelOpen]);

  const handleFullyOpen = useCallback(() => {
    setIsRepeatBookingFlowPanelFullyOpen(true);
  }, []);

  const handleBookClick = useCallback((pckg) => {
    setBooking(pckg.recentBooking);

    if (pckg.recentBooking) {
      loadPartnerAvailability(pckg.recentBooking.partner.id); // Time slots
    }

    if (clientWidth() < theme.responsive.breakpoints.lg) {
      setIsRepeatBookingFlowPanelOpen(true);
    } else {
      setIsRepeatBookingFlowPopupOpen(true);
    }
  }, []);

  const _bundles = packages;

  return (
    <>
      <ScrollToTopOnMount />
      <div className="lg:tbk-grid lg:tbk-grid-cols-account-cards lg:tbk-gap-5"></div>

      <ChatWindow />

      <Modal
        appElement={/*__CLIENT__ && */ document.getElementById("#app")}
        isOpen={isInfoModalOpen}
        // onAfterOpen={afterOpenInfoModal}
        onRequestClose={closeInfoModal}
        style={
          clientWidth() < theme.responsive.breakpoints.sm
            ? modalStyles.infoMobile
            : Object.assign(
                {},
                { ...modalStyles.info },
                { content: { ...modalStyles.info.content, width: "480px" } }
              )
        }
        contentLabel="Info Modal"
      >
        <div className="modal-info tbk-flex tbk-flex-col tbk-items-center">
          <button
            onClick={closeInfoModal}
            className="tbk-absolute tbk-top-2 tbk-right-2 tbk-z-10 tbk-hidden tbk-text-coral"
          >
            <CloseIcon />
          </button>
          <h3 className="tbk-text-title-bold tbk-mt-0 tbk-mb-1 tbk-text-center tbk-text-grey-main">
            {infoModalTitle}
          </h3>

          <p className="tbk-text-main tbk-mb-4 tbk-max-w-full tbk-text-center tbk-text-grey-main">
            {infoModalMsg}
          </p>
          <CheckNiceIcon size={64} className="tbk-mb-4 tbk-text-green" />
          <Button className="tbk-w-full" onClick={closeInfoModal}>
            Okay
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Messages;
