/* global Sentry */
import React, {
  createContext,
  useContext,
  /*useReducer, */ useState,
} from "react";
import * as Sentry from "@sentry/browser";

import useAuth, { getAccessToken } from "./useAuth/useAuth";
import useCustomer from "./useCustomer/useCustomer";
import usePartner from "./usePartner/usePartner";
import useBooking from "./useBooking/useBooking";
import usePayment from "./usePayment/usePayment";
import useEvent from "./useEvent/useEvent";

export const StateContext = createContext(null); // <StateContextType>(null!);

export default function AppStateProvider(props) {
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isEventFetching, setIsEventFetching] = useState(false);
  const [isProductsFetching, setIsProductsFetching] = useState(false);
  const [isAavailablePartnersFetching, setIsAavailablePartnersFetching] =
    useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [referralCode, setReferralCode] = useState("");
  // const [filtersBookingDate, setFiltersBookingDate] = useState();
  const [products, setProducts] = useState([]);
  const [productsLoaded, setProductsLoaded] = useState(false);
  const [bookingsWithoutRating, setBookingsWithoutRating] = useState([]); // Should it be in useBooking???
  const [promoCode, setPromoCode] = useState("");
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [hasEmailVerificationError, setHasEmailVerificationError] =
    useState(false);

  const [questionnaire, setQuestionnaire] = useState();
  const [questionnaireState, setQuestionnaireState] = useState("dob");
  const [userDoB, setUserDoB] = useState("");
  const [gender, setGender] = useState("");
  const [userPreferredWorkouts, setUserPreferredWorkouts] = useState([]);

  let contextValue = {
    error,
    setError,
    isFetching,
    isProductsFetching,
    isEventFetching,
    isAavailablePartnersFetching,
    phoneNumber,
    setPhoneNumber,
    products,
    productsLoaded,
    promoCode,
    setPromoCode,
    referralCode,
    setReferralCode,
    emailConfirmed,
    hasEmailVerificationError,
    questionnaire,
    questionnaireState,
    setQuestionnaireState,
    userDoB,
    setUserDoB,
    gender,
    setGender,
    userPreferredWorkouts,
    setUserPreferredWorkouts,
  };

  const checkPhoneNumber = (phoneCode, phoneNumber) => {
    setIsFetching(true);

    return contextValue
      .checkPhoneNumber(phoneCode, phoneNumber)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const checkEmail = (email) => {
    setIsFetching(true);

    return contextValue
      .checkEmail(email)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const verifyPhoneNumber = ({ phoneCode, phoneNumber, authVendor }) => {
    setIsFetching(true);

    return contextValue
      .verifyPhoneNumber(phoneCode, phoneNumber, authVendor)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const verifyEmail = (code, email) => {
    setIsFetching(true);

    return contextValue
      .verifyEmail(code, email)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const verifyPhoneNumberOrEmailForSimpleLogin = ({
    phoneCode,
    phoneNumber,
    email,
  }) => {
    setIsFetching(true);

    return contextValue
      .verifyPhoneNumberOrEmailForSimpleLogin({ phoneCode, phoneNumber, email })
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const verifyPhoneNumberPasswordReset = (phoneCode, phoneNumber, code) => {
    setIsFetching(true);

    return contextValue
      .verifyPhoneNumberPasswordReset(phoneCode, phoneNumber, code)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const verifyCode = ({ phoneCode, phoneNumber, code, authVendor }) => {
    setIsFetching(true);
    return contextValue
      .verifyCode(phoneCode, phoneNumber, code, authVendor)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const verifyEmailCodeForLogin = (code, email) => {
    setIsFetching(true);
    return contextValue
      .verifyEmailCodeForLogin(code, email)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const resetPassword = (params) => {
    setIsFetching(true);
    return contextValue
      .resetPassword(params)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const signUp = (
    firstName,
    lastName,
    password,
    phoneCode,
    phoneNumber,
    code,
    email,
    region
  ) => {
    setIsFetching(true);

    return contextValue
      .signUp(
        firstName,
        lastName,
        password,
        phoneCode,
        phoneNumber,
        code,
        email,
        region
      )
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        if (typeof Sentry !== "undefined") {
          Sentry.captureException(`register failed: ${err}`, {
            extra: {
              response: err && err.response,
            },
          });
        }

        return Promise.reject(err);
      });
  };

  const signIn = ({ phoneCode, phoneNumber, email, password }) => {
    setIsFetching(true);

    return contextValue
      .signIn({ phoneCode, phoneNumber, email, password })
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const sendSupportRequest = (name, email, subject, request) => {
    setIsFetching(true);

    return contextValue
      .sendSupportRequest(name, email, subject, request)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const confirmCustomerEmail = (customerId, email, code) => {
    const { cancel, promise } = contextValue.confirmCustomerEmail(
      customerId,
      email,
      code
    );

    setIsFetching(true);
    setEmailConfirmed(false);
    setHasEmailVerificationError(false);

    return {
      cancel,
      promise: promise
        .then((res) => {
          setIsFetching(false);
          setEmailConfirmed(true);
          return res;
        })
        .catch((err) => {
          setError(err);
          setIsFetching(false);
          setHasEmailVerificationError(true);
          // setEmailConfirmed(false);
          return Promise.reject(err);
        }),
    };
  };

  contextValue = {
    ...contextValue,
    ...useAuth(),
    ...useCustomer(),
    ...usePartner(),
    ...useBooking(),
    ...usePayment(),
    ...useEvent(),
    loadProducts: async (
      region = "US",
      type = "OFFLINE",
      sort = "createdAt"
    ) => {
      const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/products/v1/products?region=${region}&type=${type}&sort=${sort}`; //&page=0&size=1&sort=string`;

      return fetch(endpointUrl, {
        headers: {
          "Content-Type": "application/json",
        },
      }).then(async (res) => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const productsError = new Error(
            jsonResponse.message || "There was an error fetching products"
          );
          productsError.code = jsonResponse.code;
          return Promise.reject(productsError);
        }

        return jsonResponse;
      });
    },
  };

  const loadProducts = (region, type, sort) => {
    setIsFetching(true);
    setIsProductsFetching(true);
    return contextValue
      .loadProducts(region, type, sort)
      .then((res) => {
        setProductsLoaded(true);

        setProducts(res.data.content);
        setIsFetching(false);
        setIsProductsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        setIsProductsFetching(false);
        return Promise.reject(err);
      });
  };

  const loadAvailablePartners = (params, region) => {
    const { cancel, promise } = contextValue.loadAvailablePartners(
      params,
      region
    );

    setIsFetching(true);
    setIsAavailablePartnersFetching(true);

    return {
      cancel,
      promise: promise
        .then((res) => {
          setIsFetching(false);
          setIsAavailablePartnersFetching(false);
          return res;
        })
        .catch((err) => {
          setError(err);
          setIsFetching(false);
          setIsAavailablePartnersFetching(false);
          return Promise.reject(err);
        }),
    };
  };

  const loadAvailablePartnersBySpecialisation = (specialisation, region) => {
    const { cancel, promise } =
      contextValue.loadAvailablePartnersBySpecialisation(
        specialisation,
        region
      );

    setIsFetching(true);

    return {
      cancel,
      promise: promise
        .then((res) => {
          setIsFetching(false);
          return res;
        })
        .catch((err) => {
          setError(err);
          setIsFetching(false);
          return Promise.reject(err);
        }),
    };
  };

  const updateCustomerInfo = (params, userId) => {
    setIsFetching(true);
    return contextValue
      .updateCustomerInfo(params, userId)
      .then((res) => {
        contextValue.setUser(res.data);
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const deleteCustomerAccount = (userId) => {
    setIsFetching(true);
    return contextValue
      .deleteCustomerAccount(userId)
      .then((res) => {
        contextValue.setUser(null);
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const createBooking = (params, region) => {
    const { cancel, promise } = contextValue.createBooking(params, region);

    setIsFetching(true);

    return {
      cancel,
      promise: promise
        .then((res) => {
          setIsFetching(false);
          return res;
        })
        .catch((err) => {
          setError(err);
          setIsFetching(false);
          return Promise.reject(err);
        }),
    };
  };

  const loadBookings = (customerId, status, pageableParams, region) => {
    setIsFetching(true);
    return contextValue
      .loadBookings(customerId, status, pageableParams, region)
      .then((res) => {
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const loadPastBookings = (customerId, status, pageableParams, region) => {
    setIsFetching(true);
    return contextValue
      .loadPastBookings(customerId, status, pageableParams, region)
      .then((res) => {
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const registerPaymentMethod = (params) => {
    setIsFetching(true);
    return contextValue
      .registerPaymentMethod(params)
      .then((res) => {
        setIsFetching(false);
        contextValue.setPaymentMethods(
          contextValue.paymentMethods.concat(res.data)
        );
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const createPayment = (params) => {
    setIsFetching(true);
    return contextValue
      .createPayment(params)
      .then((res) => {
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const getBookingItems = ({ partnerId, region }) => {
    setIsFetching(true);
    return contextValue
      .getBookingItems(partnerId, region)
      .then((res) => {
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const getBookingPackageItems = (partnerId, region) => {
    setIsFetching(true);
    return contextValue
      .getBookingPackageItems(partnerId, region)
      .then((res) => {
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const getMembershipItems = ({ partnerId, region }) => {
    setIsFetching(true);
    return contextValue
      .getMembershipItems(partnerId, region)
      .then((res) => {
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const getCustomerCart = (customerId) => {
    setIsFetching(true);
    return contextValue
      .getCustomerCart(customerId)
      .then((res) => {
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const createCustomerCart = (customerId) => {
    const { cancel, promise } = contextValue.createCustomerCart(customerId);

    setIsFetching(true);

    return {
      cancel,
      promise: promise
        .then((res) => {
          setIsFetching(false);
          return res;
        })
        .catch((err) => {
          setError(err);
          setIsFetching(false);
          return Promise.reject(err);
        }),
    };
  };

  const addProductToCart = (cartId, params) => {
    const { cancel, promise } = contextValue.addProductToCart(cartId, params);

    setIsFetching(true);

    return {
      cancel,
      promise: promise
        .then((res) => {
          setIsFetching(false);
          return res;
        })
        .catch((err) => {
          setError(err);
          setIsFetching(false);
          return Promise.reject(err);
        }),
    };
  };

  const removeProductsFromCart = (cartId, customerId) => {
    const { cancel, promise } = contextValue.removeProductsFromCart(
      cartId,
      customerId
    );

    setIsFetching(true);

    return {
      cancel,
      promise: promise
        .then((res) => {
          setIsFetching(false);
          return res;
        })
        .catch((err) => {
          setError(err);
          setIsFetching(false);
          return Promise.reject(err);
        }),
    };
  };

  // Not used
  const emptyCart = (customerId) => {
    const { cancel, promise } = contextValue.emptyCart(customerId);

    setIsFetching(true);

    return {
      cancel,
      promise: promise
        .then((res) => {
          setIsFetching(false);
          return res;
        })
        .catch((err) => {
          setError(err);
          setIsFetching(false);
          return Promise.reject(err);
        }),
    };
  };

  const deleteCustomerCart = (cartId, customerId) => {
    const { cancel, promise } = contextValue.deleteCustomerCart(
      cartId,
      customerId
    );

    setIsFetching(true);

    return {
      cancel,
      promise: promise
        .then((res) => {
          setIsFetching(false);
          return res;
        })
        .catch((err) => {
          setError(err);
          setIsFetching(false);
          return Promise.reject(err);
        }),
    };
  };

  const createCheckoutSession = (params, isPreview) => {
    setIsFetching(true);
    return contextValue
      .createCheckoutSession(params, isPreview)
      .then((res) => {
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        if (typeof Sentry !== "undefined") {
          Sentry.captureException(err, {
            extra: {
              response: err && err.response,
            },
          });
        }

        return Promise.reject(err);
      });
  };

  const loadPaymentMethods = (userId, queryParams) => {
    setIsFetching(true);
    return contextValue
      .loadPaymentMethods(userId, queryParams)
      .then((res) => {
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const cancelBooking = (bookingId, customerId) => {
    const { cancel, promise } = contextValue.cancelBooking(
      bookingId,
      customerId
    );

    setIsFetching(true);

    return {
      cancel,
      promise: promise
        .then((res) => {
          setIsFetching(false);
          return res;
        })
        .catch((err) => {
          setError(err);
          setIsFetching(false);
          return Promise.reject(err);
        }),
    };
  };

  const loadReviews = (partnerId, page = 0) => {
    setIsFetching(true);
    return contextValue
      .loadReviews(partnerId, page)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const sendReview = (userId, rating, review, partnerId, bookingId) => {
    setIsFetching(true);
    return contextValue
      .sendReview(userId, rating, review, partnerId, bookingId)
      .then((res) => {
        setIsFetching(false);

        const bookings = [...bookingsWithoutRating].filter(
          (booking) => booking.id !== bookingId
        );

        setBookingsWithoutRating(bookings);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const loadBookingsWithoutRating = (userId) => {
    setIsFetching(true);
    return contextValue
      .loadBookingsWithoutRating(userId)
      .then((res) => {
        setIsFetching(false);

        setBookingsWithoutRating(res.data.content);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const checkAppleLogin = (identityToken, authorizationCode) => {
    setIsFetching(true);

    return contextValue
      .checkAppleLogin(identityToken, authorizationCode)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const signUpWithApple = (
    firstName,
    lastName,
    password,
    phoneCode,
    phoneNumber,
    code,
    email,
    identityToken,
    authorizationCode,
    region
  ) => {
    setIsFetching(true);

    return contextValue
      .signUpWithApple(
        firstName,
        lastName,
        password,
        phoneCode,
        phoneNumber,
        code,
        email,
        identityToken,
        authorizationCode,
        region
      )
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const loginWithApple = (identityToken, authorizationCode) => {
    setIsFetching(true);

    return contextValue
      .loginWithApple(identityToken, authorizationCode)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const checkGoogleLogin = (credential) => {
    setIsFetching(true);

    return contextValue
      .checkGoogleLogin(credential)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const signUpWithGoogle = (
    firstName,
    lastName,
    // password,
    phoneCode,
    phoneNumber,
    code,
    email,
    credential,
    region
  ) => {
    setIsFetching(true);

    return contextValue
      .signUpWithGoogle(
        firstName,
        lastName,
        // password,
        phoneCode,
        phoneNumber,
        code,
        email,
        credential,
        region
      )
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const loginWithFacebook = (identityToken, authorizationCode) => {
    setIsFetching(true);

    return contextValue
      .loginWithFacebook(identityToken, authorizationCode)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const checkFacebookLogin = (accessToken) => {
    setIsFetching(true);

    return contextValue
      .checkFacebookLogin(accessToken)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const signUpWithFacebook = (
    firstName,
    lastName,
    phoneCode,
    phoneNumber,
    code,
    email,
    accessToken,
    region
  ) => {
    setIsFetching(true);

    return contextValue
      .signUpWithFacebook(
        firstName,
        lastName,
        phoneCode,
        phoneNumber,
        code,
        email,
        accessToken,
        region
      )
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const loginWithGoogle = (identityToken, authorizationCode) => {
    setIsFetching(true);

    return contextValue
      .loginWithGoogle(identityToken, authorizationCode)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const loadEvent = (customerId, eventId) => {
    const { cancel, promise } = contextValue.loadEvent(customerId, eventId);

    setIsFetching(true);
    setIsEventFetching(true);

    return {
      cancel,
      promise: promise
        .then((res) => {
          setIsFetching(false);
          setIsEventFetching(false);
          return res;
        })
        .catch((err) => {
          setError(err);
          setIsFetching(false);
          setIsEventFetching(false);
          return Promise.reject(err);
        }),
    };
  };

  const loadStartedEvents = () => {
    setIsFetching(true);
    return contextValue
      .loadStartedEvents()
      .then((res) => {
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const loadUpcomingEvents = (
    customerId,
    status,
    pageableParams,
    region,
    city
  ) => {
    const { cancel, promise } = contextValue.loadUpcomingEvents(
      customerId,
      status,
      pageableParams,
      region,
      city
    );

    setIsFetching(true);

    return {
      cancel,
      promise: promise
        .then((res) => {
          setIsFetching(false);
          return res;
        })
        .catch((err) => {
          setError(err);
          setIsFetching(false);
          return Promise.reject(err);
        }),
    };
  };

  const loadCompletedEvents = (customerId, status, pageableParams, region) => {
    setIsFetching(true);
    return contextValue
      .loadCompletedEvents(customerId, status, pageableParams, region)
      .then((res) => {
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const loadCustomerUpcomingEvents = (
    customerId,
    status,
    pageableParams,
    region
  ) => {
    setIsFetching(true);
    return contextValue
      .loadCustomerUpcomingEvents(customerId, status, pageableParams, region)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const loadCustomerMembership = (customerId) => {
    setIsFetching(true);
    return contextValue
      .loadCustomerMembership(customerId)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        // We're capturing it in the context function itself
        // if (typeof Sentry !== "undefined") {
        //   Sentry.captureException(err, {
        //     extra: {
        //       response: err && err.response,
        //     },
        //   });
        // }

        return Promise.reject(err);
      });
  };

  const pauseEventMembership = (customerId) => {
    setIsFetching(true);
    return contextValue
      .pauseEventMembership(customerId)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const unpauseEventMembership = (customerId) => {
    setIsFetching(true);
    return contextValue
      .unpauseEventMembership(customerId)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const loadOffers = (region) => {
    setIsFetching(true);
    return contextValue
      .loadOffers(region)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const subscribeMembership = (customerId) => {
    setIsFetching(true);
    return contextValue
      .subscribeMembership(customerId)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const joinEvent = (eventId, customerId) => {
    setIsFetching(true);
    return contextValue
      .joinEvent(eventId, customerId)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const leaveEvent = (eventId, customerId) => {
    setIsFetching(true);
    return contextValue
      .leaveEvent(eventId, customerId)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const cancelEventMembership = (customerId, reasons) => {
    const { cancel, promise } = contextValue.cancelEventMembership(
      customerId,
      reasons
    );

    setIsFetching(true);

    return {
      cancel,
      promise: promise
        .then((res) => {
          setIsFetching(false);
          return res;
        })
        .catch((err) => {
          setError(err);
          setIsFetching(false);
          return Promise.reject(err);
        }),
    };
  };

  const cancelEvent = (eventId, customerId) => {
    const { cancel, promise } = contextValue.cancelEvent(eventId, customerId);

    setIsFetching(true);

    return {
      cancel,
      promise: promise
        .then((res) => {
          setIsFetching(false);
          return res;
        })
        .catch((err) => {
          setError(err);
          setIsFetching(false);
          return Promise.reject(err);
        }),
    };
  };

  const sendRegistrationLink = (email) => {
    setIsFetching(true);
    return contextValue
      .sendRegistrationLink(email)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const getQuestionnaire = () => {
    setIsFetching(true);

    return contextValue
      .getQuestionnaire()
      .then((res) => {
        setIsFetching(false);

        setQuestionnaire(res.data.list);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  const completeQuestionnaire = (params /*, customerId*/) => {
    setIsFetching(true);

    return contextValue
      .completeQuestionnaire(params)
      .then((res) => {
        setIsFetching(false);

        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);

        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider
      value={{
        ...contextValue,
        checkPhoneNumber,
        checkEmail,
        verifyPhoneNumber,
        verifyEmail,
        verifyPhoneNumberOrEmailForSimpleLogin,
        verifyPhoneNumberPasswordReset,
        verifyCode,
        verifyEmailCodeForLogin,
        signUp,
        signIn,
        checkAppleLogin,
        signUpWithApple,
        loginWithApple,
        checkGoogleLogin,
        signUpWithGoogle,
        loginWithGoogle,
        checkFacebookLogin,
        signUpWithFacebook,
        loginWithFacebook,
        sendSupportRequest,
        resetPassword,
        loadProducts,
        loadAvailablePartners,
        loadAvailablePartnersBySpecialisation,
        updateCustomerInfo,
        deleteCustomerAccount,
        createBooking,
        loadBookings,
        loadPastBookings,
        cancelBooking,
        loadBookingsWithoutRating,
        loadReviews,
        sendReview,
        registerPaymentMethod,
        createPayment,
        loadPaymentMethods,
        getBookingItems,
        getBookingPackageItems,
        getMembershipItems,
        getCustomerCart,
        createCustomerCart,
        deleteCustomerCart,
        addProductToCart,
        removeProductsFromCart,
        emptyCart,
        createCheckoutSession,
        confirmCustomerEmail,
        bookingsWithoutRating,
        loadEvent,
        loadStartedEvents,
        loadUpcomingEvents,
        loadCompletedEvents,
        loadCustomerUpcomingEvents,
        loadCustomerMembership,
        loadOffers,
        subscribeMembership,
        joinEvent,
        leaveEvent,
        cancelEventMembership,
        cancelEvent,
        sendRegistrationLink,
        getQuestionnaire,
        completeQuestionnaire,
      }}
    >
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }

  return context;
}
